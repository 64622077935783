const PhoneIcon = ({color, callback}) => (

<div className="iconbox">
    <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.33416 52.92199">

        <g id="Ebene_1-2">
            <path d="m13.43841,1.85899s-.794-1.85899-2.006-1.85899c-1.193,0-1.814.55-2.228.93-.414.381-7.337,6.07-7.337,6.07C1.86741,7-.14559,8.758.00841,12.06599c.13,3.307.777,8.015,4.135,14.573,3.334,6.543,11.679,16.54,16.952,20.055,0,0,4.886,3.748,9.432,5.27,1.321.417,3.962.958,4.578.958.625,0,1.729,0,2.997-.926,1.29-.936,8.523-6.753,8.523-6.753,0,0,1.771-1.599-.286-3.459-2.067-1.859-8.34-5.995-9.683-7.082-1.344-1.104-3.259-.618-4.087.13-.825.753-2.299,1.992-2.48,2.148-.269.207-1.008.878-1.835.544-1.054-.416-5.374-2.762-9.379-8.242-3.982-5.475-4.419-7.264-5.014-9.198-.207-.589.017-1.243.542-1.581.596-.414,2.792-2.244,2.792-2.244,0,0,1.422-1.403.828-3.055-.594-1.653-4.585-11.345-4.585-11.345Z"  fill={color} />
            { callback ? 
            <>
            <path fill={color} d="m27.45361,10.19869c-.717,0-1.298.581-1.298,1.298v2.596c0,.716.581,1.297,1.298,1.297h15.886c.717,0,1.298-.581,1.298-1.297v-1.298c0-1.434-1.162-2.596-2.596-2.596h-14.588Z" />
            <path fill={color} d="m27.30341,7.80019l-4.441,4.441c-.39.39-.39,1.023,0,1.414l4.134,4.133"/>
            <path fill={color} d="m44.63761,12.79439c0-1.434-1.162-2.596-2.596-2.596h-1.298c-.716,0-1.297.581-1.297,1.298v15.886c0,.717.581,1.298,1.297,1.298h2.596c.717,0,1.298-.581,1.298-1.298v-14.588Z"/> 
            </>: '' }
        </g>
    </svg>
</div>
);
export default PhoneIcon;