import { Link, NavLink,  useNavigate } from 'react-router-dom';
import {useEffect, useState, useRef}  from "react";
import { Draggable, Droppable } from '@syncfusion/ej2-base';
import { useTranslation } from 'react-i18next';
// import Move from '../assets/svg/Move';

const LowerHeader = (props) => {   // Menu items definition

    let pos = localStorage.getItem('lowerheader_position') ? JSON.parse(localStorage.getItem('lowerheader_position')) : ' ';

    const lowerheaderElem = useRef();
    const [lowerHeaderPosition, setLowerHeaderPosition] = useState(pos);
    const { t } = useTranslation();

    useEffect(() => {
        localStorage.setItem('lowerheader_position', JSON.stringify(lowerHeaderPosition));
    }, [lowerHeaderPosition]);


    let Lowerclasses = `lowerheader ${lowerHeaderPosition}`

    useEffect(() => {
        let draggable = new Draggable(document.getElementById('lowerheader'), { 
            clone: false, 
            dragArea: '#lowerheaderwrap',
            handle:'.dragpoint ',
            dragStart: (e) => {
                lowerheaderElem.current.classList.remove('left');
                lowerheaderElem.current.classList.remove('right');
                lowerheaderElem.current.classList.remove('center');  
                document.getElementById('droppable1').classList.add('show');      
                document.getElementById('droppable2').classList.add('show');      
                document.getElementById('droppable3').classList.add('show');      
      
            },    
            dragStop: (e) => {
                document.getElementById('droppable1').classList.remove('show');      
                document.getElementById('droppable2').classList.remove('show');      
                document.getElementById('droppable3').classList.remove('show');      
             
                if(e.target == document.getElementById('droppable1') ){ // = left
                    lowerheaderElem.current.classList.add('left')
                    setLowerHeaderPosition('left');
          
    
                }
 
                else if(e.target == document.getElementById('droppable3')  ){ // = right
                lowerheaderElem.current.classList.add('right')
                setLowerHeaderPosition('right');
          
           
            
                }
                else{  //= Center
                
                    lowerheaderElem.current.classList.remove('left');
                    lowerheaderElem.current.classList.remove('right');
                    lowerheaderElem.current.classList.add('center');
                    setLowerHeaderPosition('center');
                    e.target = document.getElementById('droppable2')
            
                }
            }
          
        });
      
    }, []);

    
    return (
    <div id='lowerheaderwrap'>
        <div id="droppable1" className='droparea'></div>
        <div id="droppable2" className='droparea'></div>
        <div id="droppable3" className='droparea'></div>

        <div className={Lowerclasses} id="lowerheader" ref={lowerheaderElem}>
         
            <div className="dragpoint center">
               
            </div>
            <ul>
                <li><NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>{t('dashboard')}</NavLink></li>
                <li><NavLink to="/overview" className={({ isActive }) => (isActive ? 'active' : '')}>{t('overview')}</NavLink></li>
                <li><a href="#">{t('download.download_plural')}</a></li>
                <li><NavLink to="/support" className={({ isActive }) => (isActive ? 'active' : '')}>{t('support')}</NavLink></li>     
            </ul>
        </div>
    </div>
    
    );
}
export default LowerHeader;

