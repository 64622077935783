import { NavLink } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import {  useTranslation } from 'react-i18next';

const SettingsNavigation = () => {
    const { auth } = useAuth();
    const { t } = useTranslation();

    return (
        <>        
            <ul className="settingsnavigation">
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')}to="/settings/generalsettings">
                        <span>{t('generalSettings')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')}to="/settings/overviewsettings">
                        <span>{t('overviewSettings')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')}to="/settings/alerts">
                        <span>{t('alert.alert_plural')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/downloads">
                        <span>{t('download.download_plural')}</span>
                    </NavLink>
                </li>

            {auth.userInfo.key_user ? 
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/roles">
                        <span>{t('role.role_plural')}</span>
                    </NavLink>
                </li> :
                ''
            }

            {auth.userInfo.modes.includes(21) ? 
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/users">
                        <span>{t('user')}</span>
                    </NavLink>
                </li> :
                ''
            }
                

                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/molds">
                        <span>{t('moldConnection.moldConnection_plural')}</span>
                    </NavLink>
                </li>
            </ul> 
        </>
    )
}

export default SettingsNavigation
