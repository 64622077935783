import React, { useState, useEffect } from 'react';

const HourlyDivs = ({ startTime }) => {
  const [hourlyDivs, setHourlyDivs] = useState([]);

  useEffect(() => {
    const generateHourlyDivs = () => {
      const divs = [];
      const start = new Date(startTime);
      start.setMinutes(0, 0, 0); // Minuten, Sekunden und Millisekunden auf 0 setzen

      const formatHour = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        return `${hours}:00`;
      };

      for (let i = 0; i < 25; i += 2) {
        const hour = new Date(start.getTime() + i * 60 * 60 * 1000); // Startzeit + i Stunden
        divs.push(formatHour(hour));
      }

      setHourlyDivs(divs);
    };

    generateHourlyDivs();
  }, [startTime]);

  return (
    <div>
      {hourlyDivs.map((time, index) => (
        <div className="tick" key={index}>{time}</div>
      ))}
    </div>
  );
};

export default HourlyDivs;