
const LabelValue = ({block, mold_data}) => {   

    return (
   <>
    <h3 className="mb-2">{(block.label ? block.label : block.name)}</h3>
    {(mold_data[block.value] ? mold_data[block.value] : 'No value found for' + block.value)}
   
   </>
    
    );
}
export default LabelValue;

