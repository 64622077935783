import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import { useState, useEffect } from "react";
import Components from "../hooks/components.js";
import { Row,  Card } from 'react-bootstrap';

const MoldOverview = ({listedMolds, fullDisplayMode}) => {   
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [activeElementsLess, setActiveElementsLess] = useState([]);
    const [activeElementsFull, setActiveElementsFull] = useState([]);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {              
                });
                
                setActiveElementsLess(response.data.overview_settings_less !== null ? response.data.overview_settings_less : []);
                setActiveElementsFull(response.data.overview_settings_more !== null ? response.data.overview_settings_more : []);

            } catch (error) {
                console.log(error);
            }
        }

        getSettings();

        return () => {
        }
    }, [])

    let arr;
    arr = fullDisplayMode ? activeElementsFull : activeElementsLess;
    
    return (
    <>   
    <div className="moldoverview my-3" id="moldOverviewLess">
    {
        listedMolds.map((moldId, index) => 
            <Card className="mb-3" key={index}>
                <Row>
                    {arr.map((Element, subindex) => { // Element = activeElementsFull/-Less = OverviewElement
            
                        return (
            
                                <div className={`area col-${Element.size}`}  key={subindex}>
                                    {Components(Element, moldId )}
                                </div>
                           
                        )
                    })
                }
                  
                </Row>
            </Card>
        )               
    }   

       
    </div>
   

   </>
    
    );
}
export default MoldOverview;

