import { Card } from 'react-bootstrap';

const Downloads = () => {

    return (
        <>     
      <Card.Title>Downloads </Card.Title> 
        <Card.Body>

        </Card.Body>
                                 
                    
        </>
    )
}

export default Downloads;