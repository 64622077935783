const UserIcon = ({color}) => (

<div className="iconbox">

    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color} stroke="none">
            <path d="M2420 5114 c-322 -40 -591 -171 -815 -398 -199 -201 -313 -415 -372
            -696 -24 -118 -24 -382 0 -500 59 -281 174 -496 372 -696 201 -203 421 -322
            705 -381 117 -24 380 -24 502 0 270 54 494 174 694 372 202 199 322 421 381
            705 24 118 24 382 0 500 -39 185 -110 356 -212 510 -63 95 -258 291 -351 352
            -161 107 -335 180 -506 213 -81 16 -328 28 -398 19z"/>
            <path d="M2023 2104 c-560 -68 -1065 -385 -1390 -874 -191 -286 -300 -624
            -319 -985 -6 -111 -5 -124 15 -163 14 -28 34 -48 61 -62 39 -20 54 -20 2170
            -20 2116 0 2131 0 2170 20 27 14 47 34 61 62 20 39 21 52 15 163 -26 493 -214
            925 -556 1279 -324 335 -742 539 -1199 586 -151 15 -889 11 -1028 -6z"/>
        </g>
    </svg>

</div>
);
export default UserIcon;