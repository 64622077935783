import { useState, useEffect, useContext } from "react";
import ToggleDark from '../components/toggleDark';
import { ThemeContext } from '../context/ThemeContext';
import { Card, Col, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';


const GenerSettings = () => {
    const { theme, toggle, dark } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <>     
            <Card.Title>
            {t('generalSettings')}
            </Card.Title> 

            <Card.Body>
            <Row>
                <Col sm={4}>
                    <h4>{t('colorSettings')}</h4>
                    <ToggleDark
                        toggle={toggle}
                        dark={dark}
                        label={t('darkmode')}>

                    </ToggleDark>            
                </Col>
                <Col sm={4}>
                <h4>{t('languageSettings')}</h4>
                <LanguageSwitcher />
                </Col>
            </Row>
            </Card.Body>            
        </>
    )
}

export default GenerSettings;
