import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, ScatterSeries, LineSeries, Zoom, StripLine  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';


// Datepicker format:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

   
const Breathing = ({ selectedTool }) => {

    let chart = useRef(null); 
    const axiosPrivate = useAxiosPrivate(); 
    const [breathingData, setBreathingData] = useState([]);
    createSpinner({
        target: document.getElementById('panel_10_content'),
    });

    const [message, setMessage] = useState();

    const date = new Date();
    
    const [dateRange, setDaterange] = useState(7);

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
  
    let LimitStaticObject = (localStorage.getItem("ToolBreathingLimitStatic") ? JSON.parse(localStorage.getItem("ToolBreathingLimitStatic")) : null);
    const [staticLimit, setStaticLimit] = useState(LimitStaticObject ? LimitStaticObject.limitStaticActive : false);
    const [notifyStatic , setNotifyStatic]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Red);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Yellow);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 100);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 90);

    let LimitStaticObject2 = (localStorage.getItem("ToolBreathingLimitStatic2") ? JSON.parse(localStorage.getItem("ToolBreathingLimitStatic2")) : null);
    const [staticLimit2, setStaticLimit2] = useState(LimitStaticObject2 ? LimitStaticObject2.limitStaticActive : false);
    const [notifyStatic2 , setNotifyStatic2 ]= useState(LimitStaticObject2 ? LimitStaticObject2.notify : false);
    const [color3, setColor3]= useState(LimitStaticObject2 ? LimitStaticObject2.color1 : Blue);
    const [color4, setColor4]= useState(LimitStaticObject2 ? LimitStaticObject2.color2  : PrimaryColor);
    const [limitStatic3, setLimitStatic3]= useState(LimitStaticObject2 ? LimitStaticObject2.limitStatic1 : 110);
    const [limitStatic4, setLimitStatic4] = useState(LimitStaticObject2 ? LimitStaticObject2.limitStatic2 : 80);


   
    // const today = new Date(date);
    // const tomorrow = new Date(date);
    // tomorrow.setDate(date.getDate() + 1);

    // let LSStart = localStorage.getItem('session_centering_start');
    // let LSEnd = localStorage.getItem('session_centering_ende');

    // var diffDays = tomorrow.getTime() - today.getTime(); 
    // diffDays = diffDays / (1000 * 3600 * 24);

    // const [dateRange, setDaterange] = useState(diffDays);


    // const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    // const [scatterDaterange, setScatterDaterange] = useState([ new Date(LSStart ? LSStart :  today), new Date(LSEnd ? LSEnd :  tomorrow) ]);// first and last day of current week

    
    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    let LSStart = localStorage.getItem('session_breathing_start');
    let LSEnd = localStorage.getItem('session_breathing_end');


    const [breathingDateRange, setBreathingDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(breathingDateRange[0]);
    let end = new Date(breathingDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

   
    let helpDialogInstance;

    useEffect(() => {
        showSpinner(document.getElementById('panel_10_content'));
        let unMounted = false;
        const getCenterData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

                let start = new Date (breathingDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (breathingDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                
                const response = await axiosPrivate.get('/molddata/breathing/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
            !unMounted &&
            setBreathingData([...response.data])
            if (response.data.length === 0) {
                setMessage('Change Date to continue');
            }

            }
            else{
                setMessage('Select mold from map to continue');
            }
            hideSpinner(document.getElementById('panel_10_content'))
        
        }

        getCenterData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, breathingDateRange]);  


    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        localStorage.setItem("ToolBreathingLimitStatic", null); 

        if(staticLimit){
            const staticObject = {
                limitStaticActive : staticLimit,
                limitStatic1 : limitStatic1, 
                limitStatic2 : limitStatic2, 
                color1 : color1, 
                color2 : color2, 
                notify : notifyStatic,
            }
            localStorage.setItem('ToolBreathingLimitStatic', JSON.stringify(staticObject)); 
        }
        localStorage.setItem("ToolBreathingLimitStatic2", null); 

        if(staticLimit2){
            const staticObject2 = {
                limitStaticActive : staticLimit2,
                limitStatic1 : limitStatic3, 
                limitStatic2 : limitStatic4, 
                color1 : color3, 
                color2 : color4, 
                notify : notifyStatic2,
            }
            localStorage.setItem('ToolBreathingLimitStatic2', JSON.stringify(staticObject2)); 
        }
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'Save and close',
                isPrimary: true,
            }
        }
    ];



    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
        setDaterange(e.daySpan);
        handleBreathingDateChange(start, end);
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end); 

        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleBreathingDateChange(newstart, newend);
    };
    const handleBreathingDateChange = (start, ende) => { 
        let temparray = [start, ende] 
        setBreathingDateRange(temparray);
        localStorage.setItem('session_breathing_start', start);
        localStorage.setItem('session_breathing_end', ende);
     };
    let rgba = ' rgba(256,256,256,.3) ';

    const marker =  {
        visible: true, 
        width: 3, 
        height: 3, 
        fill: PrimaryColor, 
        border: { width: 2, color: PrimaryColor },
        dataLabel: { visible: false, position: 'Top',   font: { fontWeight: '600', color: TextColor }  },
        // width: 4, height: 4, shape: 'Circle'
    };

    const primaryxAxis = { 
        valueType: 'DateTime',  
        // title: 'Time', 
        labelFormat: 'dd.MM - h:mm',
        //  interval: 1, 
        labelRotation:  -45 , 
        labelStyle: {color: TextColor}, 
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        enableAutoIntervalOnZooming: true,
    };
    const primaryyAxis = {
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        labelStyle: {color: TextColor}, 
        lineStyle: {width: 0  },
        labelFormat: 'n1' ,
        stripLines: [
            { start: limitStatic1, size:  2, sizeType: 'Pixel', color: color1, visible: staticLimit, opacity: 1 }, 
            { start: limitStatic2, size:  2, sizeType: 'Pixel', color: color2, visible: staticLimit, opacity: 1 },
            { start: limitStatic3, size:  2, sizeType: 'Pixel', color: color3, visible: staticLimit2, opacity: 1 }, 
            { start: limitStatic4, size:  2, sizeType: 'Pixel', color: color4, visible: staticLimit2, opacity: 1 }
            
        ]
        // minimum: -200, maximum: 200
    };
    const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const margin = {  left: 100, right: 100, top: 40, bottom: 40};
const helpButtonClick = () => {
    helpDialogInstance.show();
}
const loaded = () => {
    hideSpinner(document.getElementById('panel_10_content'));
   }

return (
<>   
    <div className="widgetheader">
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Tool Breathing' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_10_content'
            > Description for ToolBreathing Component  </DialogComponent>

        <DialogComponent 
        id="defaultdialogScatter" 
        showCloseIcon={false} 
        zIndex = {9999}
        width="500px" 
        header="Settings" 
        visible={status}
        target="#panel_10_content"
        buttons={buttons}
        open={dialogOpen}
        close={dialogClose}
        position={{ X: 'center', Y: '40' }}
        >
          <Row>                
            <Col sm={5} className='py-3'>  
                        <label htmlFor="switch1"> Static Limits </label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch1" name='static' checked={staticLimit} change={() => setStaticLimit(!staticLimit)} />
                    </Col>
                    {(staticLimit == false ?  null : 
                        <>  
                            <LimitStatic name="BreathingStatic1" color={color1} setColor={setColor1} value={limitStatic1} setValue={setLimitStatic1} placeholder="Upper Limit" useActivate={false} min={limitStatic2}  />

                            <LimitStatic name="BreathingStatic2" color={color2} setColor={setColor2} value={limitStatic2} setValue={setLimitStatic2} placeholder="Lower Limit" useActivate={false} max={limitStatic1} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox1"> Notification by exceeding Limit </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox1" name='notificationstatic' checked={notifyStatic} change={() => setNotifyStatic(!notifyStatic)} />
                            </Col>
                        </>
                    )}
                    <Col sm={5} className='py-3'>  
                        <label htmlFor="switch2"> Additional Static Limits </label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch2" name='static' checked={staticLimit2} change={() => setStaticLimit2(!staticLimit2)} />
                    </Col>
                     {(staticLimit2 == false ?  null : 
                        <>  
                            <LimitStatic name="BreathingStatic3" color={color3} setColor={setColor3} value={limitStatic3} setValue={setLimitStatic3} placeholder="Upper Limit" useActivate={false} min={limitStatic4}  />

                            <LimitStatic name="BreathingStatic4" color={color4} setColor={setColor4} value={limitStatic4} setValue={setLimitStatic4} placeholder="Lower Limit" useActivate={false} max={limitStatic3} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox2"> Notification by exceeding Limit </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox2" name='notificationstatic' checked={notifyStatic2} change={() => setNotifyStatic2(!notifyStatic2)} />
                            </Col>
                        </>
                    )}
        </Row>
        </DialogComponent>
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={start} 
            endDate={end} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"}  /> </div> 



    {(breathingData.length == 0 ? <h2 className='text-center pt-5'>{message}</h2> : 
     <div className='control-pane'>
    <ChartComponent 
        style={{ backgroundColor : BackgroundColor, color: TextColor}}
        background={BackgroundColor}
        primaryXAxis={primaryxAxis} 
        primaryYAxis={primaryyAxis} 
        margin={margin}         
        enableCanvas={true}
        enablePersistence={true}
        loaded ={loaded}
        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            toolbarItems: ['Reset'],
            mode: "X,Y",
        }}
        title='Tool Breathing'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
        chartArea={{ border: { width: 0 } }} 
        legendSettings = {{visible: false}}
        >
        <Inject services={[ScatterSeries,LineSeries, Legend, DataLabel, Category, Zoom, DateTime, StripLine]}/>
        <SeriesCollectionDirective>
            <SeriesDirective dataSource={breathingData} xName='time' yName='breathing' name="Breathingdata" type='Scatter' fill={PrimaryColor} opacity='0.7' marker={marker}></SeriesDirective>  */}
        </SeriesCollectionDirective>
    </ChartComponent>
    </div>
)}
</>
)

}
;
export default Breathing;
