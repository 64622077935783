import React, { useState, useEffect, createContext} from 'react';

const themes = {
  dark: {
    bodyClass : 'dark-content',
    backgroundColor: '#2f3031',
    textColor: '#fff', 
    primary: '#08a77f', 
    widgetBg: '#212121',
    widgetBorder: '#b5b5b3',
    yellowMid: '#e7cf01',
    red: '#e3051a',
    blue: '#2759a6'
  },
  light: {
    bodyClass : 'white-content',
    backgroundColor: '#eeefee',
    textColor: '#212121',
    primary: '#08a77f',
    widgetBg: '#fff',
    widgetBorder: '#b5b5b3',
    yellowMid: '#e7cf01',
    red: '#e3051a',
    blue: '#2759a6'
  }
}

let state = localStorage.getItem('dark');
let the = state ? themes.dark : themes.light;

const initialState = {
  dark: state,
  theme: the,
  toggle: () => {}
}


const ThemeContext = createContext(initialState);

const ThemeProvider = ({ children }) => {

  const [dark, setDark] = useState(); // Default theme is dark

  // read the preferred theme from the persistence
  useEffect(() => {
    // Accessing scss variable "--background-color"
      // and "--text-color" using plain JavaScript
      // and changing the same according to the state of "darkTheme"
     // const root = document.documentElement;
      const root = document.querySelector(':root');
      root?.style.setProperty("--backgroundColor", dark ? themes.dark.backgroundColor : themes.light.backgroundColor);
      root?.style.setProperty("--textColor", dark ? themes.dark.textColor : themes.light.textColor);
      root?.style.setProperty("--widgetBg", dark ? themes.dark.widgetBg : themes.light.widgetBg);
      //root?.style.setProperty("--bs-primary", dark ? themes.dark.primary : themes.light.primary);

      
    const isDark = localStorage.getItem('dark') === 'true'
      setDark(isDark)
  }, [dark])

  // To toggle between dark and light modes
  const toggle = () => {
    
    const isDark = !dark

    localStorage.setItem('dark', JSON.stringify(isDark))
    setDark(isDark);
  }

  const theme = dark ? themes.dark : themes.light;  

  document.body.className = '';
  document.body.classList.add(theme.bodyClass);
  
  // document.getElementById('theme').href = themefile;

  const themefile =  'material' + (dark ? '-dark' : '') + '.min.css';
  const styleTag = document.getElementById('theme');

  const fileimport = '@import "/css/' + themefile + '"';
  styleTag.innerHTML= fileimport;
 
  // ajax to read css file and paste it to #theme in index.html ...
  //const ajax = new Ajax(themefile, 'GET', true);

  // ajax.send().then((result) => {
  // const styleTag = document.getElementById('theme');
  // // styleTag.innerHTML=`/*${e.itemData.value}*/` + result;

  
  // styleTag.innerHTML= result;
 

  // });

  return (
    <ThemeContext.Provider value={{ theme, dark, toggle }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }