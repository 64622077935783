import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AppBarComponent } from "@syncfusion/ej2-react-navigations";
import LowerHeader from "./LowerHeader";
import DateTimeClock from "./DateTimeClock";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout"; 
import { Row, Col } from 'react-bootstrap';
import HomeIcon from '../assets/svg/HomeIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import SettingsIcon from '../assets/svg/SettingsIcon';
import UserIcon from '../assets/svg/UserIcon';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext } from "react";
import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const currentTheme = useContext(ThemeContext);
    let TextColor = currentTheme.theme.textColor;
  	const navigate = useNavigate();
  	const logout = useLogout();
  	const { auth } = useAuth();
    const { t } = useTranslation();

  	const signOut = async () => {
    	await logout();
    	navigate('/login');
  	}
    // https://github.com/thekietvuong/Dropdown-Menu-in-React/blob/master/src/index.css 
    const [open, setOpen] = useState(false);

    let menuRef = useRef();
  
    useEffect(() => {
      let handler = (e)=>{
        if(!menuRef.current.contains(e.target)){
          setOpen(false);
        }      
      };
  
      document.addEventListener("mousedown", handler);
      
  
      return() =>{
        document.removeEventListener("mousedown", handler);
      }
  
    });



  	return (
      <>
      <header className="shadow-sm">

  
            <Row>
                <Col sm={{ span: 1 }} className='alginIconCenter'>
                  <Link to="/"><HomeIcon color={TextColor} /></Link>
                  
                </Col>
                <Col sm={{ span: 4 }}>
                  <span className="">{auth.userInfo.companies_name}</span>
                </Col>
            
                <Col className="text-end" sm={{ span: 3, offset:1  }}>
                  <DateTimeClock />
                </Col>
                <Col sm={{ span: 2 }}>
                  <div className="float-end">
                    <ul>
                      <li>
                        <div className='menu-container' ref={menuRef}>
                          <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
                          <UserIcon color = {TextColor} />
                          </div>
                          <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
                            <ul>
                              <li className = 'dropdownItem'><b>{auth.userInfo.name}</b></li>
                              <li className = 'dropdownItem'><NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : '')}>{t('profile')}</NavLink></li>
                              <li><Link className="ext-end" onClick={signOut} >{t('signOut')}</Link></li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Link to="/settings/generalsettings">
                          <SettingsIcon color={TextColor}/>
                          </Link>
                        </li>
                      <li><HelpIcon color={TextColor}/></li>
                    </ul>
                  </div>
              </Col>
    
          	</Row>
          

        
      </header>
      <LowerHeader />
      <div></div>
      </>

   
  )
}

export default Header;