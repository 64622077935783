import React from 'react';

// Stateless Functional Component
const FormatDate = ({ date, format }) => {
    const formatDateString = (date, format) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind 0-indexiert
        const year = date.getFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0'); // pad start -> immer zweistellig
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return format
            .replace('dd', day)
            .replace('mm', month)
            .replace('YYYY', year)
            .replace('hh', hours)
            .replace('ii', minutes);
    };

    return (
        <>
            {formatDateString(new Date(date), format)}
        </>
    );
};

export default FormatDate;
