import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            await axios('/logout', {
                withCredentials: true
            });
            
            for (var i=localStorage.length-1; i>=0; i--){ // ...If you remove an item, the keys move down an index. You need to loop backwards.
                if(localStorage.key(i).substring(0,8) == 'session_'){// clear all localStorage items starting with "session_"
               
                    localStorage.removeItem(localStorage.key(i)); 
                }
               
            }
           
        } catch (err) {
            console.error(err);
        }
    }
    

    return logout;
}

export default useLogout