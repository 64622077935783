import React, { useState } from 'react';
import {  NavLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
const FallbackPanel = () => {   

    const { t } = useTranslation();

    return (
        <>   
            <div className="widgetheader text-center">
            <h3>{t('welcome')}</h3>
            
            </div>
        
        <div className="control-pane">
            <div className="px-5">
         <h3>{t('fallbackPanel.title')}</h3>

         <ol>

         {t('fallbackPanel.items', { returnObjects: true }).map((item, index) => (
          <li key={index}>
            <Trans 
              i18nKey={`fallbackPanel.items.${index}`}
              components={[
                <button className='sidebarToggler dummy'></button>
               ]}
            />
          </li>
        ))}
       
           
         </ol>
         
            
           <br />

           <Trans 
              i18nKey={"fallbackPanel.bottom"}
              components={[
                <NavLink to="/support" />
              ]}
            />

        {/* For further support please contact our  <NavLink to="/support">Support-Team</NavLink>. We are glad to help you. <br /> Enjoy the app! */}
         </div>
        </div>
    
        </>
        )
    
    };
export default FallbackPanel;