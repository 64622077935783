import useAxiosPrivate from "../hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";
import { Modal, Alert, Table, FormCheck, Row, Col } from 'react-bootstrap';
import { tools, actions, messages, downloads } from '../config/rules';
import useAuth from "../hooks/useAuth";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
// import "bootstrap/dist/css/bootstrap.min.css";

const EditRole = ({ roleId, roles, setRoles, widgets }) => { 
    const axiosPrivate = useAxiosPrivate();   
    const { auth } = useAuth();
    const [name, setName] = useState('');
    const [show, setShow] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [modes, setModes] = useState([]);
    const [selectedWidgets, setSelectedWidgets] = useState([]);
    const [allowedWidgets, setAllowedWidgets] = useState([]);
    const [role, setRole] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let allowed = [];

    function addAllowedWidget(widget_id){  
        widget_id = widget_id.widget;
        
        let res = widgets.filter((item) => { return item.id == widget_id; });

        if(res[0] !== undefined){
            let widget_to_add = { 
                id: widget_id, 
                description: res[0].description 
            }

            allowed.push(widget_to_add);
        }           
    }

    useEffect(() => {
        const getRole = async () => {
            try {
                const response0 = await axiosPrivate.get('/roles/' + roleId, {              
                });
             
                setRole(response0.data);
                setName(response0.data.name);   
                setModes(response0.data.modes);
                setSelectedWidgets(response0.data.widgets);

                const response1 = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {              
                });
             
                response1.data.widgets.map((widget_id) => addAllowedWidget(widget_id));
                setAllowedWidgets(allowed)
           
            } catch (error) {
                console.log(error);
            }
        }

        getRole();

        return () => {
        }
    }, [])

    const changeMode = (modeId) => {
        modeId = Number(modeId);  
        
        if(modes.includes(modeId)){
            setModes(modes.filter(e => e !== modeId ))   
        } else{
            setModes([...modes, modeId]);
        }
    }

    const isMode = (modeId) => {
        modeId = Number(modeId); 
        return modes.includes(modeId); 
    }

    const changeWidget = (widgetId) => {
        widgetId = Number(widgetId);  
        
        if(selectedWidgets.includes(widgetId)){
            setSelectedWidgets(selectedWidgets.filter(e => e !== widgetId ))   
        } else{
            setSelectedWidgets([...selectedWidgets, widgetId]);
        }
    }

    const isWidget = (widgetId) => {
        widgetId = Number(widgetId); 
        return selectedWidgets.includes(widgetId); 
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            let errorMessage = '';
            if(roleId !== 0){
                const data = { id: roleId, name, modes, widgets: selectedWidgets };
            
                await axiosPrivate.put(`/roles`, {
                    data
                }).catch(function(error){
                    if (error.response) {
                        errorMessage = error.response.data.message;
                    }
                }); 
            }
            else{
                const data = { companies_id:  auth.userInfo.companies_id, name, modes, widgets: selectedWidgets };
                
                await axiosPrivate.post(`/roles`, {
                    data
                }).catch(function(error){
                    if (error.response) {
                        errorMessage = error.response.data.message;
                    }
                }); 
            }
                      
            const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
            });
          
            setRoles(response.data.roles);
            
            errorMessage ? setErrMsg(errorMessage) : handleClose();   
           
        } catch (error) {
            console.log(error)    
        }
    }

    const handleDelete = async (id) => {
        try {
            await axiosPrivate.delete(`/roles`, {
                data: { id } 
            });    

            const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
            });
          
            setRoles(response.data.roles);
        } catch (error) {
            console.log(error)    
        }  

        handleClose();
    }  

    if(!auth.userInfo.key_user){ return false; }

    return (
        <>
            <ButtonComponent onClick={handleShow} className="primary float-end">
            {roleId === 0 ? 'Add' : 'Edit'}
            </ButtonComponent>

            <Modal show={show} onHide={handleClose} size="lg">
                <form onSubmit={e => handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title><h3>{roleId === 0 ? 'Add' : 'Edit'} role {role.name}</h3></Modal.Title>
                    </Modal.Header>
                              
                    <Modal.Body>
                        <Alert show={errMsg !== ''} variant="danger">{errMsg}</Alert>

                        <div className="mb-4">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                autoComplete="off"
                                required
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>            

                        <h4>Rechte</h4>
                        
                        <Table className="mb-5">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-center">Anlegen</td>
                                    <td className="text-center">Bearbeiten</td>
                                    <td className="text-center">Entfernen</td>
                                    <td className="text-center">Sehen</td>
                                </tr>
                            { Object.keys(tools).map(tool_key => {
                                return (
                                <tr>
                                    <td>{tools[tool_key]}</td>   

                                    {(actions.map((action) => 
                                
                                    <td className="text-center">
                                        {/* <FormCheck 
                                            id={`mode_${tool_key}${action}`}
                                            onChange={() => changeMode(`${tool_key}${action}`)}
                                            checked={isMode(`${tool_key}${action}`)} >
                                        </FormCheck> */}
                                        <label className="cbox noLabelTxt">
                                            <input 
                                            id={`mode_${tool_key}${action}`}
                                            onChange={() => changeMode(`${tool_key}${action}`)}
                                            checked={(isMode(`${tool_key}${action}`) ? 'checked' : '')}
                                            type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                ))}

                                </tr>)
                            })}
                        
                            </tbody>
                        </Table>

                        <h4>Benachrichtigungen</h4> 
                        <Table className="mb-5">
                            <tbody>
                                <tr>
                                    <td className="text-center">E-Mail bearbeiten</td>
                                    <td className="text-center">Verwalten</td>
                                    <td className="text-center">Frequenz bearbeiten</td>
                                </tr>
                                <tr>
                                {(messages.map((message) =>       
                                    <td className="text-center">
                                        {/* <FormCheck 
                                            id={`mode_${message}`}
                                            onChange={() => changeMode(message)}
                                            checked={isMode(message)} >
                                        </FormCheck> */}
                                        <label className="cbox noLabelTxt">
                                            <input 
                                            id={`mode_${message}`}
                                            onChange={() => changeMode(message)}
                                            checked={(isMode(message) ? 'checked' : '')}
                                            type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                ))}
                                </tr>
                            </tbody>
                        </Table>

                        <h4>Downloads</h4>                
                        <Table className="mb-5">
                            <tbody>
                                <tr>
                                    <td className="text-center">Werkzeugdaten</td>
                                    <td className="text-center">Error Log</td>
                                    <td className="text-center">Werkzeugliste</td>
                                    <td className="text-center">Benutzerliste</td>
                                </tr>
                                <tr>
                                {(downloads.map((download) =>       
                                    <td className="text-center">
                                        {/* <FormCheck 
                                            id={`mode_${download}`}
                                            onChange={() => changeMode(download)}
                                            checked={isMode(download)} >
                                        </FormCheck> */}
                                        <label className="cbox noLabelTxt">
                                            <input 
                                            id={`mode_${download}`}
                                            onChange={() => changeMode(download)}
                                            checked={(isMode(download) ? 'checked' : '')}
                                            type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                ))}
                                </tr>
                            </tbody>
                        </Table>

                        <h4>Widgets</h4>                
                        <Row>

                        {(allowedWidgets.map((widget) =>       
                            <Col sm={4}>  
                                {/* <FormCheck 
                                    id={`widget_${widget.id}`}
                                    label={widget.description}
                                    onChange={() => changeWidget(widget.id)}
                                    checked={isWidget(widget.id)} >
                                </FormCheck> */}
                                <label className="cbox ">
                                {widget.description}
                                            <input 
                                             id={`widget_${widget.id}`}
                                             onChange={() => changeWidget(widget.id)}
                                            checked={(isWidget(widget.id)? 'checked' : '')}
                                            type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                            </Col>  
                        ))}
                          
                        </Row>
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <ButtonComponent variant="danger" className="me-auto" onClick={() => handleDelete(roleId)}>
                            Delete
                        </ButtonComponent>
                        <ButtonComponent className="primary" onClick={e => handleSubmit(e)}>
                            Save
                        </ButtonComponent>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditRole;