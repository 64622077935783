import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState , useRef, useEffect    } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Crosshair, Category, Tooltip, DateTime, DataLabel, LineSeries, Zoom, ScrollBar, MultiColoredLineSeries, StripLine, SegmentsDirective, SegmentDirective} from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import HelpIcon from '../assets/svg/HelpIcon';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';
import MoldAttribute  from './MoldAttribute';

const ProductionOutputDaily = ({ selectedTool }) => {

    let chart = useRef(); 
    let helpDialogInstance;

    createSpinner({
        target: document.getElementById('panel_6_content'),
    });

    const [message, setMessage] = useState('Select different mold from map or change date to see Production Output Daily');
    const axiosPrivate = useAxiosPrivate(); 

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;

    let LSStart = localStorage.getItem('session_linechart_start');
    let LSEnd = localStorage.getItem('session_linechart_ende');

        
    const [statedLinedata, setStatedLinedata] = useState([]);
    const date = new Date();
    const endOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lineDaterangeStart = (LSStart ? new Date(LSStart) : new Date(date.getFullYear(), date.getMonth(), 1));
    const lineDaterangeEnd = (LSEnd ? new Date(LSEnd) : endOfCurrentMonth);
    const [lineDaterange, setLineDaterange] = useState([ lineDaterangeStart, lineDaterangeEnd]);// first and last day of current month

    let LimitStaticObject = (localStorage.getItem("ProductionOutputDailyLimitStatic") ? JSON.parse(localStorage.getItem("ProductionOutputDailyLimitStatic")) : null);
    const [staticLimit, setStaticLimit] = useState(LimitStaticObject ? LimitStaticObject.limitStaticActive : false);
    const [notifyStatic , setNotifyStatic ]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Yellow);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Red);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 700);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 400);


    // TimezoneOffset
    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const handleLineDateChange = (start, ende) => { 
        let temparray = [start, ende]
         setLineDaterange(temparray);
         localStorage.setItem('session_linechart_start', start);
         localStorage.setItem('session_linechart_ende', ende);
     };
      

    useEffect(() => {
        let unMounted = false;
        showSpinner(document.getElementById('panel_6_content'));
        const getLineChartData = async () => { 
            if(!selectedTool == 0 ){ // selected = mold from map/grid
                let start = new Date (lineDaterange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];
            
                let ende = new Date (lineDaterange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                const response = await axiosPrivate.get('/molddata/cycles/cycles/mold_id/'+ selectedTool +'/interval/daily/start/' + start + '/end/' + ende, {

                }).catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
    
                !unMounted &&
                    setStatedLinedata([...response.data]);
                    setMessage(''); 
            }
            else{
                setMessage('Select mold from map to continue.'); 
            }
            hideSpinner(document.getElementById('panel_6_content'))
        }
        getLineChartData();
        return () => {
            unMounted = true;
        }
    }, [selectedTool, lineDaterange])


    let startDate = new Date(lineDaterange[0]);
    let endDate = new Date(lineDaterange[1]);

    // let endDateMod = new Date (endDate.setDate(endDate.getDate() + 1));

    var diffDays = endDate.getDate() - startDate.getDate(); 

    var diffDays = endDate.getTime() - startDate.getTime(); 
    diffDays = Number((diffDays / (1000 * 3600 * 24)).toFixed()) + 1 ;

    const [dateRange, setDateRange] = useState(diffDays);

    


    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };


    let buttons;
    const dlgButtonClick = () => {
    
        localStorage.setItem("ProductionOutputDailyLimitStatic", null); 

        const staticObject = {
            limitStaticActive : staticLimit,
            limitStatic1 : limitStatic1, 
            limitStatic2 : limitStatic2, 
            color1 : color1, 
            color2 : color2, 
            notify : notifyStatic,
        }
        localStorage.setItem('ProductionOutputDailyLimitStatic', JSON.stringify(staticObject)); 

        refreshChart();
        dialogClose();
        
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'Save and close',
                isPrimary: true,
            }
        }
    ];
    
    let prodData = statedLinedata;
    
    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
        handleLineDateChange(start, end);
      }; 

    const marker =  {
        visible: true, 
        width: 8, 
        height: 8, 
        fill: Red, 
        border: { width: 4, color: PrimaryColor },
        dataLabel: { visible: true, position: 'Top',   font: { fontWeight: '500', color: TextColor }  },
    };

    const primaryxAxis = { 
        valueType: 'DateTime',  
        title: '', 
        labelFormat: 'dd.MM.y', 
        intervalType: 'Days', 
        interval: 1, 
        labelRotation:  -45 , 
        labelStyle: {color: TextColor},
        enableAutoIntervalOnZooming: true,
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        crosshairTooltip: { enable: true } 
   };

   const primaryyAxis = {
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: {width: 0  },
        stripLines: [
            { start: limitStatic1, size:  1, sizeType: 'Pixel', color: color1, visible: staticLimit, opacity: 1 }, 
            { start: limitStatic2, size:  1, sizeType: 'Pixel', color: color2, visible: staticLimit, opacity: 1 }
            
        ]
   }

    const pointRender = (args) => {
        let index = args.point.index;
        let indexColor = PrimaryColor;
         
        if(limitStatic1 !== 0 && Number(prodData[index].y) < limitStatic1){
            indexColor = color1
        }
        if(limitStatic2 !== 0 && Number(prodData[index].y) < limitStatic2){
            indexColor = color2
        }
         // neu ende

        args.fill = indexColor;
        args.border = {width: 2, color: indexColor};
    }

    const toggleDatespan = (daterange, jumpto = 'future') => {

       
        daterange = (jumpto == "future" ? daterange : -daterange);
        let start = new Date(startDate);
        let end = new Date(endDate);
       
        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

          
         var diffyDays = newend.getTime() - newstart.getTime(); 
         diffyDays = diffyDays / (1000 * 3600 * 24);

         setDateRange(diffyDays);
         handleLineDateChange(newstart, newend);
    };
    const margin = { left: 100, right: 100, top: 40, bottom: 40 };
    
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }

    const refreshChart = () => { 
        chart.current.refresh();
    }
    const loaded = () => {
        hideSpinner(document.getElementById('panel_6_content'));
    }

return (
<>   
<div className="widgetheader">
    <div className="float-start">
        <h3>{(<MoldAttribute id={selectedTool} attributes={["identifier", "code"]}/>)}</h3> 
    </div>
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Production Output ' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_6_content'
            >  
            Production Output Daily</DialogComponent>

        <DialogComponent 
            id="defaultdialogLine" 
            showCloseIcon={false} 
            zIndex = {9999}
            width="500px" 
            header="Settings - Rangecolor Threshold" 
            visible={status}
            target="#panel_6_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
            position={{ X: 'center', Y: '40' }}
        >
            
          <Row>                
               <Col sm={5} className='py-3'>  
                        <label htmlFor="switch1"> Display Limits as Lines</label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch1" name='static' checked={staticLimit} change={() => setStaticLimit(!staticLimit)} />
                    </Col>
                   
                        <>  
                            <LimitStatic name="RangecolorStatic1" color={color1} setColor={setColor1} value={limitStatic1} setValue={setLimitStatic1} placeholder="Upper Limit" useActivate={false} min={limitStatic2}  />

                            <LimitStatic name="RangecolorStatic2" color={color2} setColor={setColor2} value={limitStatic2} setValue={setLimitStatic2} placeholder="Lower Limit" useActivate={false} max={limitStatic1} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox1"> Notification by exceeding Limit </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox1" name='notificationstatic' checked={notifyStatic} change={() => setNotifyStatic(!notifyStatic)} />
                            </Col>
                        </>
                   
        </Row>
        </DialogComponent>
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={startDate} 
            endDate={endDate} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            max={date}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} fontSize={28} val={" -" + dateRange + "d"}  /> </div>
    {(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) >= new Date(date.getFullYear(), date.getMonth(), date.getDate())) ? null :   <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} fontSize={28} val={" +" + dateRange + "d"}  /> </div>}
    
    {(statedLinedata.length == 0 ? <h2 className='text-center pt-5'>{message}</h2> : 
    <ChartComponent
        style={{ backgroundColor : BackgroundColor, color: TextColor}} 
        loaded = {loaded}
        height = '100%'
        chartArea={{ border: { width: 0 } }} 
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            toolbarItems: ['Reset'],
            mode: "X",
        }}
        pointRender={pointRender}
        margin={margin} 
        title='Production Output Daily'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
        ref={chart} 
        legendSettings = {{visible: false}}
        tooltip={{ enable: true, shared: true }} 
        crosshair={{ enable: true, lineType: 'Vertical' }}
        >
            <Inject services={[LineSeries, Legend, Crosshair, Tooltip, DataLabel, Category,  DateTime, Zoom, ScrollBar, MultiColoredLineSeries, StripLine]}/>
            <SeriesCollectionDirective>
                <SeriesDirective 
                    dataSource={prodData} 
                    xName='x' 
                    yName='y' 
                    fill={PrimaryColor} 
                    width={1} 
                    name='Daily' 
                    // type='Line' 
                    type="MultiColoredLine"
                    marker={marker}
                    segmentAxis="Y"
                    close="y"
                > 
                 <SegmentsDirective>
                  
                        {/*
                          bisher
                        <SegmentDirective value={color1threshold} color={Red}></SegmentDirective>
                        <SegmentDirective value={color2threshold} color={Yellow}></SegmentDirective>
                        ende */}
                         <SegmentDirective value={limitStatic1} color={color1}></SegmentDirective>
                         <SegmentDirective value={limitStatic2} color={color2}></SegmentDirective>
                        <SegmentDirective color={PrimaryColor}></SegmentDirective>
                    </SegmentsDirective>
                    
                </SeriesDirective>
            
            </SeriesCollectionDirective>
          
       
    </ChartComponent> )  }


</>
)

}
;
export default ProductionOutputDaily;
