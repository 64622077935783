import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import EditRole from './EditRole';
import { Table } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";

const RoleList = ({ roles, setRoles }) => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [widgets, setWidgets] = useState([]);

    useEffect(() => {
        const getWidgets = async () => {
            try {
                const response = await axiosPrivate.get('/widgets', {              
                });

                setWidgets(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getWidgets();

        return () => {
        }
    }, [])

    useEffect(() => {
        const getRoles = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
              
                setRoles(response.data.roles);
            } catch (error) {
                console.log(error);
            }
        }

        getRoles();

        return () => {
        }
    }, [])

    if(!auth.userInfo.key_user){ return false; }

    return (
        <>
            <EditRole roleId={0} roles={roles} setRoles={setRoles} widgets={widgets}/>

            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
            {roles?.length
                ? (roles.map((role, i) => 
                    <tr key={role.id}>
                        <td>{role?.name}</td>
                        <td><EditRole roleId={role.id} roles={roles} setRoles={setRoles} widgets={widgets}/></td>
                    </tr>)) 
                : ''
            }

                </tbody>
            </Table>
        </>
    );
};

export default RoleList;
