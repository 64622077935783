import React, { useState, useEffect} from 'react';

export const DateTimeClock = () => {
  var [date, setDate] = useState(new Date())

  useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }
  });

  return(
      <>
        {date.toLocaleDateString()}  {date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
      </>
  )
}

export default DateTimeClock ;