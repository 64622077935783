import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, DateTime, Legend, Category, ColumnSeries, Tooltip, DataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import HelpIcon from '../assets/svg/HelpIcon';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';
import MoldAttribute  from './MoldAttribute';



const ProductionRatio = ({ selectedTool }) => {

    createSpinner({
        target: document.getElementById('panel_5_content'),
    });

    const [message, setMessage] = useState('Select different mold from map or change date to see Production Times Weekly');

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;
    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;

    const date = new Date();
    const states = ['Production', 'Stop', 'Error', 'Maintenance'];
    const colors = [PrimaryColor, Yellow, Red, Blue];

    let LimitStaticObject = (localStorage.getItem("ProductionTimesWeeklyLimitStatic") ? JSON.parse(localStorage.getItem("ProductionTimesWeeklyLimitStatic")) : null);
    const [notifyStatic1 , setNotifyStatic1 ]= useState(LimitStaticObject ? LimitStaticObject.notify1 : false);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 0.1);
    const [moreThan1, setMoreLess1]= useState(LimitStaticObject ? LimitStaticObject.moreThan1 : true);
    const [notifyStatic2 , setNotifyStatic2 ]= useState(LimitStaticObject ? LimitStaticObject.notify2 : false);
    const [limitStatic2, setLimitStatic2]= useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 0.1);
    const [moreThan2, setMoreLess2]= useState(LimitStaticObject ? LimitStaticObject.moreThan2 : true);
    const [notifyStatic3 , setNotifyStatic3 ]= useState(LimitStaticObject ? LimitStaticObject.notify3 : false);
    const [limitStatic3, setLimitStatic3]= useState(LimitStaticObject ? LimitStaticObject.limitStatic3 : 0.1);
    const [moreThan3, setMoreLess3]= useState(LimitStaticObject ? LimitStaticObject.moreThan3 : true);
    const [notifyStatic4 , setNotifyStatic4 ]= useState(LimitStaticObject ? LimitStaticObject.notify4 : false);
    const [limitStatic4, setLimitStatic4]= useState(LimitStaticObject ? LimitStaticObject.limitStatic4: 0.1);
    const [moreThan4, setMoreLess4]= useState(LimitStaticObject ? LimitStaticObject.moreThan4 : true);

  
    // actual date e.g. 25 minus daynumber (0-6) + 1
    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();

    let LSStart = localStorage.getItem('session_barchart100_start');
    let LSEnd = localStorage.getItem('session_barchart100_end');

    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString(); // toUTCString, weil LS auch noch string ist - später wird daraus new Date gemacht 

    const [activeLimits, setActiveLimits] = useState([]);

    const [barchart100range, setBarchart100range] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week or localstorage values

    let startDate = new Date(barchart100range[0]);
    let endDate = new Date(barchart100range[1]);

    let chart = useRef(null); 
    const axiosPrivate = useAxiosPrivate();   

    var diffDays = endDate.getTime() - startDate.getTime(); 
    diffDays = diffDays / (1000 * 3600 * 24)+1;

    const [dateRange, setDaterange] = useState(diffDays);
    const [legendInterval, setLegendInterval] = useState(1);
    let helpDialogInstance;

    const [status, setStatus] = useState(false);
    
    const [statedAuslastung, setStatedAuslastung] = useState([]);
    
    const handleBarchart100DateChange = (start, ende) => { 
        let temparray = [start, ende]
        setBarchart100range(temparray);
        localStorage.setItem('session_barchart100_start', start);
        localStorage.setItem('session_barchart100_end', ende);
     };

    useEffect(() => {
        let unMounted = false;
        showSpinner(document.getElementById('panel_5_content'));
        const getBarChart100Data = async () => {
            if(!selectedTool == 0 ){
                let start = barchart100range[0].toISOString().split('T', 1)[0];
                let ende = barchart100range[1].toISOString().split('T', 1)[0];
                const response = await axiosPrivate.get('/molddata/ratio/1/mold_id/'+ selectedTool +'/interval/daily/start/' + start + '/end/' + ende , {
                }).catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
            !unMounted &&
            setStatedAuslastung([...response.data]);
            setMessage('');
            }
            else{
               setMessage('Select different mold from map to see Production Times Weekly')
            }
            hideSpinner(document.getElementById('panel_5_content'));
        }

        getBarChart100Data();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, barchart100range])

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };



    useEffect(() => {
        getActiveLimits();
      }, [notifyStatic1, notifyStatic2, notifyStatic3, notifyStatic4]);
 
      const checkNotifications = () => {
        return notifyStatic1 || notifyStatic2 || notifyStatic3 || notifyStatic4;
      };

      const getActiveLimits = () => {
         const tlimits = [];
    
        if (notifyStatic1) {
            tlimits.push([1, limitStatic1 ]);
        }
        if (notifyStatic2) {
            tlimits.push([2, limitStatic2 ]);
        }
        if (notifyStatic3) {
            tlimits.push([3, limitStatic3 ]);
        }
        if (notifyStatic4) {
            tlimits.push([4, limitStatic4 ]);
        }
    

        setActiveLimits(tlimits);
    };


    function adjustDataIfFuture(dataArray) {
        const today = new Date();

        return dataArray.map(item => {
            const date = new Date(item.x);
            // Prüfen, ob das Datum in der Zukunft liegt
            if (date > today) {
            return { ...item, stop: 0, stop_acc: 0, production: 0, production_acc:0 };
            }
            return item;
        });
      }

     

    const data = adjustDataIfFuture(statedAuslastung);

    // useEffect(() => {
    //     adjustDataIfFuture(data)
        
    //   }, [statedAuslastung]);


    let roundedCorner= {
        topLeft: Browser.isDevice ? 8 : 16, 
        topRight: Browser.isDevice ? 8 : 16, 
        bottomRight:  Browser.isDevice ? 8 : 16,  
    };
    
    const tooltip = { 
        enable: true, 
    };  
    const tooltipRender = (args) => {
        let orgDate = args.data.pointX;
        let Date = orgDate.getDate()+'.'+(orgDate.getMonth()+1)+'.'+ orgDate.getFullYear()
        let text = Date  + ' : ' + args.point.tooltip.toFixed(2) + ' %';
        args.text = text;
    };

    let buttons;
    const dlgButtonClick = () => {

        localStorage.setItem('ProductionTimesWeeklyLimitStatic', null); 
        const staticObject = {
            limitStatic1 : limitStatic1, 
            limitStatic2 : limitStatic2, 
            limitStatic3 : limitStatic3, 
            limitStatic4 : limitStatic4, 
            notify1 : notifyStatic1,
            notify2 : notifyStatic2,
            notify3 : notifyStatic3,
            notify4 : notifyStatic4,
            moreThan1: moreThan1,
            moreThan2: moreThan2,
            moreThan3: moreThan3,
            moreThan4: moreThan4
        }
        localStorage.setItem('ProductionTimesWeeklyLimitStatic', JSON.stringify(staticObject)); 
        
        getActiveLimits();
        dialogClose();
        
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'save',
                isPrimary: true,
            }
        }
    ];
    useEffect(() => {
        if(chart.current){
            refreshChart();
        }
        
      }, []);

      const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
        setDaterange(e.daySpan);
        handleBarchart100DateChange(start, end);

    }; 
    useEffect(() => {
       setLegendInterval(dateRange < 7 ? 25 : 1) //Display bug? showing lots of labels when less than 7 days
      }, [dateRange]);

    
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        let start = new Date(startDate);
        let end = new Date(endDate);
       
        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleBarchart100DateChange(newstart, newend);
    };
    // const margin = { left: 80, right: 80, top: 60, bottom: 30 };
    const margin = { left: 80, right: 80, top: 8, bottom: 30 };
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }
    const pointRender = (args) => {
        if(args.point.yValue < 16){
            args.series.properties.cornerRadius.properties.topLeft = args.point.yValue;
            args.series.properties.cornerRadius.properties.topRight = args.point.yValue;
            args.series.properties.cornerRadius.properties.bottomRight = 0;
        }
        else{
            args.series.properties.cornerRadius.properties.topLeft = 16;
            args.series.properties.cornerRadius.properties.topRight = 16;
            args.series.properties.cornerRadius.properties.bottomRight = 16;   
        }
    }
    const loaded = () => {
        hideSpinner(document.getElementById('panel_5_content'));
    }

    return (
    <>
    
<div className="widgetheader">
    <div className="float-start">
        <h3><MoldAttribute id={selectedTool}  attributes={["mold_identifier", "code"]}  /></h3> 
    </div>
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header=' Production Times ' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
             target='#panel_5_content'
            >  Production Times Weekly </DialogComponent>

        <DialogComponent 
        id="defaultdialogStacked" 
        showCloseIcon={true} 
        zIndex = {9999}
        width="500px" 
        header="Notifications" 
        visible={status}
         target="#panel_5_content"
        buttons={buttons}
        open={dialogOpen}
        close={dialogClose}
        position={{ X: 'center', Y: '40' }}>
            
          <Row>                
           <Col>
            <div className="mb-3 bold">Notify when state reaches specific value</div>
            
           <Row>
            <Col sm={3}>
                <SwitchComponent name='production1MoreLess' cssClass='higherLower mt-4' onLabel="<" offLabel=">" checked={moreThan1} disabled={!notifyStatic1} change={() => setMoreLess1(!moreThan1)}/>
            </Col> 
            <Col sm={9}>
                <LimitStatic name="production" value={limitStatic1} setValue={setLimitStatic1} placeholder="Production" min={0} useActivate={true} status={notifyStatic1} setStatus={setNotifyStatic1} format="p2" step={0.01} />
            </Col>
            <Col sm={3}>
                <SwitchComponent name='production2MoreLess' cssClass='higherLower mt-4' onLabel="<" offLabel=">" checked={moreThan2} disabled={!notifyStatic2} change={() => setMoreLess2(!moreThan2)}/></Col> 
            <Col sm={9}>
                <LimitStatic name="stop" value={limitStatic2} setValue={setLimitStatic2} placeholder="Stop" min={0} useActivate={true} status={notifyStatic2} setStatus={setNotifyStatic2} format="p2" step={0.01} />
            </Col>
            <Col sm={3}>
                <SwitchComponent name='production3MoreLess' cssClass='higherLower mt-4' onLabel="<" offLabel=">" checked={moreThan3} disabled={!notifyStatic3} change={() => setMoreLess3(!moreThan3)}/></Col> 
            <Col sm={9}>
                <LimitStatic name="error" value={limitStatic3} setValue={setLimitStatic3} placeholder="Error" min={0} useActivate={true} status={notifyStatic3} setStatus={setNotifyStatic3} format="p2" step={0.01} />
            </Col>
            <Col sm={3}>
                <SwitchComponent name='production4MoreLess' cssClass='higherLower mt-4' onLabel="<" offLabel=">" checked={moreThan4} disabled={!notifyStatic4}  change={() => setMoreLess4(!moreThan4)}/></Col> 
            <Col sm={9}>
                <LimitStatic name="maintenance" value={limitStatic4} setValue={setLimitStatic4} placeholder="Maintenance" min={0} useActivate={true} status={notifyStatic4}  setStatus={setNotifyStatic4} format="p2" step={0.01} />
            </Col>
            </Row>

            </Col>
        </Row>
        </DialogComponent>
        
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={startDate} 
            endDate={endDate} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            max={lastDayofCurrentWeek}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    {(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) >= new Date(new Date(lastDayofCurrentWeek).getFullYear(), new Date(lastDayofCurrentWeek).getMonth(), new Date(lastDayofCurrentWeek).getDate())) ? null :  <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"}  /> </div>}
    
    {checkNotifications() ? 
        <div className="activeNotifications"> 
            <h2>Active Notifications</h2>
          
            <ul>
            {activeLimits.map(([label, value]) => (
                <li key={label} style={{color: colors[label-1]}}>
                    <strong>{states[label-1]}:</strong> {value*100} %
                </li>
            ))}
            </ul>
        </div> 
    : null}
   

    {(data.length == 0 ? <h2 className='text-center pt-5'>{message}</h2> : 
   
     <div className='control-pane'>
            <div className='control-section'>
                <ChartComponent 
                id='charts' 
                locale='de-de'
                title='Production Times Weekly'
                titleStyle={{color: TextColor, fontWeight: '400', position: 'Top'}}
                ref={chart} 
                    enableSideBySidePlacement={false} 
                    style={{ textAlign: "center", backgroundColor : BackgroundColor, color: TextColor}} 
                    legendSettings={{ 
                        textStyle: { color: TextColor },}}
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: 'EEEE <br> dd.MM.y', 
                        labelIntersectAction: 'Rotate45',
                        
                        lineBreakAlignment: 'Center',

                        interval: legendInterval, // display bug?
                        majorGridLines: { width: 0 },
                        minorGridLines: { width: 0 },
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 },
                        labelStyle: {color: TextColor},
                    }} 
                    primaryYAxis={{
                        rangePadding: 'None',
                        minimum: 0,
                        interval: 20,
                        intervalType: 'Auto', 
                        majorTickLines: { width: 0 },
                        majorGridLines: { width: 0 },
                        minorGridLines: { width: 0 },
                        minorTickLines: { width: 0 },
                        labelStyle: {color: TextColor},
                            lineStyle: {width: 0  }
                    }} 
                    width={Browser.isDevice ? '100%' : '75%'} 
                    height='100%'
                    chartArea={{ border: { width: 0 } }} 
                    loaded = {loaded}
                    pointRender={pointRender}
                    // tooltip={{ enable: true, format: '${point.x} : <b>${point.y} </b>' }}>
                    tooltip={tooltip}
                    tooltipRender={tooltipRender}
                    margin = { margin }
                    >
            <Inject services={[ColumnSeries, DataLabel, Category, Tooltip, Legend, DateTime]}/>
            <SeriesCollectionDirective>
                <SeriesDirective 
                    dataSource = {data} 
                    fill = {colors[0]} 
                    cornerRadius = {roundedCorner}
                    width = {2} 
                    zOrder = {0}     
                    tooltipMappingName = 'production'
                    xName  ='x' 
                    yName = 'production_acc' 
                    name = 'Production' 
                    type = 'Column' 
                    columnWidth = {0.8} />
                
                <SeriesDirective 
                    dataSource = {data}  
                    fill = {colors[1]} 
                    cornerRadius = {roundedCorner}
                    zOrder = {1}     
                    xName = 'x' 
                    width = {2} 
                    tooltipMappingName = 'stop'
                    yName = 'stop_acc' 
                    name = 'Stop' 
                    type = 'Column' 
                    columnWidth = {0.8} />
               
                <SeriesDirective 
                    dataSource = {data} 
                    fill = {colors[2]} 
                    cornerRadius = {roundedCorner}
                    zOrder = {2}     
                    tooltipMappingName = 'error'
                    xName = 'x' 
                    width = {2} 
                    yName = 'error_acc' 
                    name = 'Error' 
                    type = 'Column' 
                    columnWidth = {0.8}  />
               
                <SeriesDirective 
                    dataSource = {data} 
                    fill= {colors[3]} 
                    cornerRadius = {roundedCorner}
                    zOrder = {50}     
                    tooltipMappingName = 'maintenance'
                    xName = 'x' 
                    width = {2}
                    yName = 'maintenance_acc' 
                    name = 'Maintenance' 
                    type = 'Column' 
                    columnWidth = {0.8} />
            </SeriesCollectionDirective>
        </ChartComponent>
            
            </div>
        </div>
    )}
    </>
    )
    ;
}
;
export default ProductionRatio;