const PinIcon = ({color, pinned}) => (
<div className="iconbox">

    {!pinned ?   <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.15608 154.58603">
<path d="m106.74023,0c.98415.66105,2.107,1.1828,2.93178,2.00393,13.83602,13.77467,27.63532,27.58622,41.44153,41.39083,2.81231,2.81199,2.7455,5.05204-.35575,7.53986-4.43861,3.56063-9.34211,4.15299-14.56915,1.9797-.75878-.31548-1.18226-.33858-1.77021.3485-12.49596,14.60254-25.02235,29.17905-37.54074,43.76242-.12757.14862-.22738.32105-.39336.5585,2.16913,2.58273,3.73373,5.47229,4.33265,8.80968,1.18755,6.61744-.58966,12.34787-5.29518,17.13432-1.77371,1.80422-4.19249,1.65641-6.1399-.28684-7.5868-7.57065-15.16346-15.1515-22.73375-22.73866-.38765-.38851-.68116-.87093-1.10068-1.4165-.64691.61186-1.07862,1.00474-1.49341,1.41473-12.63074,12.48458-24.80288,25.44681-38.22752,37.10607-6.18934,5.37542-12.59368,10.50413-18.92856,15.71059-1.81699,1.49335-3.57199,1.63506-5.14599.60154-1.37147-.90054-2.11546-2.73777-1.57451-4.35987.27672-.8298.81408-1.60766,1.3642-2.30617,11.15769-14.16711,23.07994-27.64969,35.97569-40.26802,5.57434-5.45442,11.03354-11.02644,16.56203-16.52797.38761-.38572.91642-.62953,1.37957-.93933.01768-.22584.03536-.45167.05305-.67751-.47533-.29573-1.02813-.5137-1.41457-.89865-7.98545-7.95476-15.95362-15.92686-23.92128-23.89945-2.47963-2.48117-2.42954-4.71478.14142-7.0805,6.83408-6.28851,17.02557-6.70615,24.35837-.99812.27686.21551.5532.43167.94761.73948,14.98679-12.86289,29.97732-25.72899,44.88024-38.51988-.48643-1.78705-1.13303-3.43837-1.36261-5.14576-.61529-4.57615.85541-8.52651,4.08647-11.79393C103.76491.69968,104.55652.40796,105.23058,0c.50322,0,1.00644,0,1.50966,0Z" fill={color}/></svg> :
<svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.15607 162.38013" fill={color}>
    <path d="m151.11353,43.39478c-13.80621-13.80463-27.60553-27.61615-41.44153-41.39087-.82477-.82111-1.94763-1.34283-2.93176-2.00391h-1.50964c-.67407.40796-1.4657.69971-2.00293,1.24292-3.23102,3.26746-4.70172,7.21783-4.08643,11.79395.22955,1.7074.87616,3.3587,1.36261,5.14575-13.57324,11.6496-27.2207,23.36298-40.87018,35.07812l38.61548,66.88391c2.62061-4.03485,3.48767-8.62567,2.56781-13.75128-.59894-3.33734-2.16351-6.22693-4.33264-8.80963.16602-.23749.26581-.40991.39337-.55853,12.51837-14.58337,25.0448-29.15985,37.54071-43.76239.58795-.68707,1.01147-.664,1.7702-.34851,5.22705,2.17328,10.13055,1.58093,14.56915-1.97968,3.10126-2.48785,3.16809-4.72791.35577-7.53986Z"/>
    <path d="m30.31757,56.96118c-2.57092,2.36572-2.62103,4.5993-.14142,7.08051,7.96771,7.9726,15.93585,15.9447,23.92133,23.89941.38641.38495.93921.60297,1.41455.89868-.0177.22583-.0354.45166-.05304.67749-.46313.30981-.992.55365-1.37958.93933-5.5285,5.50153-10.98767,11.07355-16.56201,16.52802-12.89575,12.61829-24.81799,26.10089-35.97571,40.26801-.55011.69849-1.08746,1.47638-1.3642,2.30615-.54095,1.62213.203,3.45935,1.57452,4.35986,1.57397,1.03351,3.32898.89185,5.146-.6015,6.33484-5.20648,12.7392-10.33521,18.92853-15.71063,13.42462-11.65924,25.5968-24.62146,38.22754-37.10608.41479-.40997.8465-.80286,1.49341-1.41473.41949.54559.71301,1.02802,1.10065,1.4165.79883.8006,1.59991,1.599,2.39893,2.39948l-29.24152-50.64789c-3.43573.61139-6.73541,2.17456-9.48798,4.7074Z"/>
    <rect x="61.17627" y="-6.88246" width="11.74159" height="178.26894" transform="translate(-32.1434 44.54321) rotate(-30)"/></svg> }
</div>
);
export default PinIcon;