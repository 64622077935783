import { Html } from '@react-email/html';
import { Head } from '@react-email/head';
import { Body } from '@react-email/body';
import { Container } from '@react-email/container';

const AlertEmail = ({type, name, contact, request}) => {
    const STYLES = `
    body {
        background-color: #E9E9E9;
    `;

    return (
      <Html lang="en">
        <Head>
            <title>moldlife cloud</title>
            <style>
                {STYLES}
            </style>
        </Head>
        <Body>
            <Container>
                <h1>{type} by {name}</h1>
                Contact: {contact} 
                {request ? 'Request' + request : ''}
            </Container>
        </Body>
      </Html> 
    )
}

export default AlertEmail