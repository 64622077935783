import useAxiosPrivate from "../hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";
import { Modal, Alert } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {  useTranslation } from 'react-i18next';


const EditUser = ({ user, users, setUsers }) => { 
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.name);
    const [pwd, setPwd] = useState('');
    const [roleId, setRoleId] = useState(user.roles_id);
    const [show, setShow] = useState(false);
    const [roles, setRoles] = useState({});

    const [errMsg, setErrMsg] = useState('');

    const { t } = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const getRoles = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {              
                });
                
                setRoles(response.data.roles);         
            } catch (error) {
                console.log(error);
            }
        }

        getRoles();

        return () => {
        }
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, name]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            let errorMessage = '';
            
            if(user.id !== 0){
                const data = { id: user.id, email, name, pwd, roleId };
                
                await axiosPrivate.put(`/users`, {
                    data
                }).catch(function(error){
                    if (error.response) {
                        errorMessage = error.response.data.message;
                    }
                }); 
            }
            else{
                const data = { email, name, pwd, roleId, companyId: auth.userInfo.companies_id };
                
                await axiosPrivate.post(`/users`, {
                    data: data
                }).catch(function(error){
                    if (error.response) {
                            errorMessage = error.response.data.message;
                    }
                });  
            }
            
            const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
            });
    
            setUsers(response.data.users);

            errorMessage ? setErrMsg(errorMessage) : handleClose();   
           
        } catch (error) {
            console.log(error)    
        }
    }

    const handleDelete = async (id) => {
        if(!auth.userInfo.modes.includes(27)){ return false; }

        try {
            await axiosPrivate.delete(`/users`, {
                data: { id }
            });    

            setUsers(users.filter(user => {
                return user.id !== id;
            }))
        } catch (error) {
            console.log(error)    
        }  

        handleClose();
    }  

    if(!auth.userInfo.modes.includes(23) && user.id === 0){ return false; }
    if(!auth.userInfo.modes.includes(25) && user.id !== 0){ return false; }

    return (
        <>
            <ButtonComponent  onClick={handleShow} className="float-end primary">
                {user.id === 0 ?  t('buttons.add.add') : t('buttons.edit.edit')}
            </ButtonComponent>

            <Modal show={show} onHide={handleClose}>
                <form onSubmit={e => handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title><h3>{user.id === 0 ? t('buttons.add.addUser') : t('buttons.edit.editUser')}</h3></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert show={errMsg !== ''} variant="danger">{errMsg}</Alert>

                        
                        <div className="mb-2">
                            <label htmlFor="name">{t('name')}</label> 
                            <input
                                type="text"
                                id="name"
                                autoComplete="off"
                                required
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="email">{t('eMail')}</label>
                            <input
                                type="email"
                                id="email"
                                autoComplete="off"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="pwd">{t('newPassword')}</label>
                            <input
                                type="password"
                                id="pwd"
                                autoComplete="off"
                                required
                                className="form-control"
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                            />
                        </div>
                        <div className="mb-2">
        
                            <div className="selectbox mt-4">
                                <select 
                                    value={roleId}
                                    onChange={e => setRoleId(e.target.value)}>
                                    <option disabled hidden value={0}>{t('role')}</option>
                                    {roles?.length
                                    ? (roles.map((role, i) => 
                                    <option value={role.id}>
                                        {role.name}
                                    </option>)) 
                                : ''
                            }
                                </select>
                                </div>
                        </div>

                    </Modal.Body>
                    
                    <Modal.Footer>
                        {(auth.userInfo.modes.includes(27) && user.id !== 0) ?
                            <ButtonComponent variant="danger" className="me-auto" onClick={() => handleDelete(user.id)}>
                                {t('buttons.delete')}
                            </ButtonComponent> :
                            ''
                        }
                        <ButtonComponent className="primary" type="submit" onClick={e => handleSubmit(e)}>
                        {t('buttons.save')}
                        </ButtonComponent>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditUser;