import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { Card, Row, Col, Alert } from 'react-bootstrap';
import { useState,useEffect } from "react";
import { overviewElements } from '../config/overviewElements';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import SettingsIcon from '../assets/svg/SettingsIcon';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const OverviewSettings = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [notice, setNotice] = useState('');
    const [noticeType, setNoticeType] = useState('');

    let DialogInstance;
    let ListInstance;
    let buttons;
    let smallcount = 0;
    let bigcount = 0;

    const [activeElementsSmall, setActiveElementsSmall] = useState([]);
    const [activeElementsBig, setActiveElementsBig] = useState([]);

    const [smallview, setSmallview] = useState([]);
    const [bigview, setBigview] = useState([]);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {              
                });
                
                setActiveElementsSmall(response.data.overview_settings_less !== null ? removeOldWidgets(response.data.overview_settings_less, overviewElements) : []);   
                setActiveElementsBig(response.data.overview_settings_more !== null ? removeOldWidgets(response.data.overview_settings_more, overviewElements) : []);   
            } catch (error) {
                console.log(error);
            }
        }

        getSettings();

        return () => {
        }
    }, [])

    useEffect(() => {
        activeElementsSmall.map((elem) => {
            smallcount = smallcount + Number(elem.size);
        })
        setSmallview(smallcount);

        activeElementsBig.map((elem) => {
            bigcount = bigcount + Number(elem.size);
        })
        setBigview(bigcount);

        

        // return () => {
        //     unMounted = true;
        // }
    }, [activeElementsBig, activeElementsSmall])


    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };
    
    // nur direkt möglich / nicht in Dialogcomp.
    const changeOverviewElementSmall = (element_key) => {
        if(activeElementsSmall.some(e => e.id === element_key.id)){ // remove
            setActiveElementsSmall(activeElementsSmall.filter(e => e.id !== element_key.id))   
        } 
        else{
            setActiveElementsSmall([...activeElementsSmall, element_key]);
        }         
    }

    const changeOverviewElementBig = (element_key) => {
        if(activeElementsBig.some(e => e.id === element_key.id)){
            setActiveElementsBig(activeElementsBig.filter(e => e.id !== element_key.id))   
        } 
        else{
            setActiveElementsBig([...activeElementsBig, element_key]);
        }    
    }

    const isOverviewElementSmall = (element_key) => {
        return activeElementsSmall.some(e => e.id === element_key);
    }

    const isOverviewElementBig = (element_key) => {
        return activeElementsBig.some(e => e.id === element_key);
    }
    
    let fields = { text: "name" };

   
    const reOrderListSmall = (e) =>{  // ondrop (list) erzeugt neu geordnete liste
       let ar =  e.source.currentData;
       setActiveElementsSmall(ar);
       
    }
    const reOrderListBig = (e) =>{  // ondrop (list) erzeugt neu geordnete liste
        let ar =  e.source.currentData;
        setActiveElementsBig(ar);
        
     }
    // const dlgButtonClick = () => {
      

    //     var array = []
    //     var form = document.getElementById('elementCboxes');
    //     const checkboxes = form.querySelectorAll('input[type=checkbox]:checked');
        
 
    //     checkboxes.forEach((box) => {

    //         if(activeElements.find(bird => bird.id == box.value)) {
    //             // console.log('already in array = no add');  
    //         }
    //         else{
    //             array.push(overviewElements.find((x)=> x.id == box.value))
    //               // console.log('added');
    //         }

    //     })
    //     let newarry = [...activeElements, ...array]
    //     setActiveElements(newarry);
    //     dialogClose();  
    // };
    // buttons = [
    //     {
    //         click: dlgButtonClick,
    //         buttonModel: {
    //             content: 'save',
    //             isPrimary: true,
    //         }
    //     }
    // ];

    const handleSubmit = async e => {
        e.preventDefault();
     
        try {
            const data = { id: auth.userInfo.id, overview_settings_less: activeElementsSmall, overview_settings_more: activeElementsBig };
           
            await axiosPrivate.patch(`/users`, {
                data
            })  
            .catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });

            setNotice('Changes saved');
            setNoticeType('success');     
        } catch (error) {
            console.log(error)    
            setNotice(error);
            setNoticeType('danger');
        }
    }

    const removeOldWidgets = (activeWidets, availableWidets) => {
        const array2Ids = new Set(availableWidets.map(item => item.id));

        // Filtert array1 und entfernt Objekte, deren ID nicht in array2 enthalten ist
        return activeWidets.filter(item => array2Ids.has(item.id));

    }

    return (
        <>     
      <Card.Title>OverviewSettings </Card.Title> 
        <Card.Body>

{/* 
        <DialogComponent 
        id="helper" 
        header='Choose Elements to display' 
        showCloseIcon={true} 
        visible={false}
        width='250px' 
        height='500px'
        buttons={buttons}
        ref={dialog => DialogInstance = dialog} 
        closeOnEscape={false} 
        visible={status}
        open={dialogOpen}
        close={dialogClose}
        // target='#moldOverviewLess'
        >   */}
        <Alert show={notice !== ''} variant={noticeType}>{notice}</Alert>

        <h2>Available Widets</h2>
        <Row>
            <Col sm={8}>

            </Col>
            <Col sm={2} className='text-center'>
                Small view
            <span id='alreadyInUseSmall' className="bold"> {smallview}</span>/12
            </Col>
            <Col sm={2} className='text-center'>
                Big view
                <span id='alreadyInUseBig' className="bold"> {bigview}</span>/24
            </Col>
        </Row>
        <form id="elementCboxes">
            <ul className="oddeven">
           
            {overviewElements.map((overviewElement, index) => {
                return (
                <>
                <li key={index}>
                    <Row>
                        <Col sm={3}>{overviewElement.name}
                        </Col>
                        <Col sm={5}>{overviewElement.description}
                        </Col>
                        <Col sm={2}  className='text-center'>
                            <label className="cbox">
                                <input 
                                    id={`overviewElement_${overviewElement.id}`}
                                    name="element"
                                    onChange={x => changeOverviewElementSmall(overviewElement)} //funktioniert nicht im Overlay
                                    value={overviewElement.id}
                                    checked={(isOverviewElementSmall(overviewElement.id) ? 'checked' : '')}
                                    type="checkbox"
                                />
                                <span className="checkmark"></span>
                            </label>    
                        </Col>
                        <Col sm={2}  className='text-center'>
                            <label className="cbox">
                                <input 
                                    id={`overviewElement_${overviewElement.id}`}
                                    name="element"
                                    onChange={x => changeOverviewElementBig(overviewElement)}// funktioniert nicht im Overlay
                                    value={overviewElement.id}
                                    checked={(isOverviewElementBig(overviewElement.id) ? 'checked' : '')}
                                    type="checkbox"
                                />
                                <span className="checkmark"></span>
                            </label>    
                        </Col>
                    </Row>
                </li>

                </>
                )
            })}
                   
            </ul>
        </form>
    {/* </DialogComponent> */}
    <Row className='mt-5'>
        <Col sm={6}>
      
                <h2>Select Widgets for small view (drag to order)</h2>
                <ListBoxComponent
                dataSource={activeElementsSmall}
                allowDragAndDrop="true" 
                fields={fields}
                drop={(e) => reOrderListSmall(e)} 
                ref={list => ListInstance = list} 
                /> 
            
        </Col>
        <Col sm={6}>
           
            <h2>Select Widgets for big view (drag to order)</h2>
                <ListBoxComponent
                dataSource={activeElementsBig}
                allowDragAndDrop="true" 
                fields={fields}
                drop={(e) => reOrderListBig(e)} 
                ref={list => ListInstance = list} 
                /> 
           
        </Col>
        {/* <div className="col-12">
            <div className="float-end" onClick={buttonClick} >
                <SettingsIcon color="#fff" />
        </div>  
           
        </div>*/}
        <Col sm={12}> <ButtonComponent isPrimary={true} onClick={handleSubmit}>Save</ButtonComponent> </Col>
    </Row>
        </Card.Body>
                                 
                    
        </>
    )
}

export default OverviewSettings;