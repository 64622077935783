import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import StandardTemplate from './views/StandardTemplate';
import SettingsTemplate from './views/SettingsTemplate';
import Login from './pages/Login';
import Missing from './pages/Missing';
import Unauthorized from './pages/Unauthorized';
import Home from './pages/Home';
import Overview from './pages/Overview';
import Alerts from './pages/Alerts';
import Downloads from './pages/Downloads';
import Users from './pages/Users';
import Roles from './pages/Roles';
import Molds from './pages/Molds';
import Support from './pages/Support';
import Profile from './pages/Profile';
import OverviewSettings from './pages/OverviewSettings';
import GeneralSettings from './pages/GeneralSettings';
import { useTranslation } from 'react-i18next';


function App() {

  const { t } = useTranslation();
  return (
    
    <Routes>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedMode={0} />}>
            <Route path="/" element={<StandardTemplate/>} >
              <Route path="/" element={<Home />} />
              <Route path="overview" element={<Overview />} />
              <Route path="molds" element={<Molds />} />
              <Route path="support" element={<Support />} />
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route path="/settings/" element={<SettingsTemplate title={t('settings')}/>} >
              <Route path="downloads" element={<Downloads />} />
              <Route path="alerts" element={<Alerts />} />
              <Route path="users" element={<Users />} />
              <Route path="roles" element={<Roles />} />
              <Route path="molds" element={<Molds />} />
              <Route path="overviewsettings" element={<OverviewSettings />} />
              <Route path="generalsettings" element={<GeneralSettings />} />
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
