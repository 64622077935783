const MailIcon = ({color}) => (

<div className="iconbox">
<svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.321 61.321">
    <g id="Ebene_1-2">
        <polygon fill={color} points="53.4069 27.1345 61.3209 35.3555 61.3209 20.3125 53.4069 27.1345"/>
        <polygon fill={color}  points="0 35.365 7.924 27.134 0 20.312 0 35.365"/>
        <g >
            <path fill={color}  d="m61.321,42.8289v7.569c0,1.83-1.485,3.316-3.315,3.316H3.315c-1.83,0-3.315-1.486-3.315-3.316v-7.569l11.852-12.303,13.539,11.661c1.408,1.207,3.276,1.878,5.269,1.878,1.994,0,3.871-.671,5.28-1.878l13.529-11.661,11.852,12.303Z"/>
            <path  fill={color}  d="m61.321,10.9131v3.315l-28.917,24.912c-.929.805-2.558.805-3.487,0L0,14.2281v-3.315c0-1.83,1.485-3.306,3.315-3.306h54.691c1.83,0,3.315,1.476,3.315,3.306"/>
        </g>
    </g>
    </svg>
</div>
);
export default MailIcon;