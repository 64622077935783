import { useTranslation } from 'react-i18next';

const Status = ({block, mold_data}) => {   

    const { t } = useTranslation();

    return (
   <>
   <div className="p-3">
    <h3 className="mb-2">{(block.label ? t(block.label) : block.name)}</h3>
    <div className={"status mx-auto " + (mold_data.active ? 'green' : '')}></div>
    </div>
   </>
    
    );
}
export default Status;

