import { useState, useContext,} from "react";
import { Row, Col, Card, Container  } from 'react-bootstrap';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import PhoneIcon from '../assets/svg/PhoneIcon';
import { ThemeContext, themes } from '../context/ThemeContext';
import MailIcon from "../assets/svg/MailIcon";
import CompanyIcon from "../assets/svg/CompanyIcon";

import useAuth from "../hooks/useAuth";

const Profile = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const currentTheme = useContext(ThemeContext);
    const [mail, setMail] = useState(auth.userInfo.email);
    const [name, setName] = useState(auth.userInfo.name);
    const [role, setRole] = useState(auth.userInfo.role_id);
    const [company, setCmpany] = useState(auth.userInfo.companies_name);

    let TextColor = currentTheme.theme.textColor;

    console.log(auth.userInfo);
   
   
    return (
        <Container className="pt-5 mt-5 generalpage">
            <Row>
                <Col xs={{span : 8, offset: 2}}>
                    <p className="mb-2">Your profile</p>

                    <Card>
                        <Row>
                            <Col xs lg={{ span: 6, offset: 3 }} className="py-5">
                                <Row>
                                    <Col xs={12}>
                                    <div className="mb-3">  <h3>{name}</h3>
                                       {role}</div> 

                                        <div className="contactoption"><MailIcon color={TextColor} /> {mail}</div>
                                        <div className="contactoption"><CompanyIcon color={TextColor} /> {company}</div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>  
                </Col>
            </Row>
        </Container>
    )
}

export default Profile;
