import { Outlet } from "react-router-dom"
import Header from "../components/Header";

const StandardTemplate = () => {

    return (
        <main className="App">       
            <Header />
            <Outlet />     
        </main>
    )
}

export default StandardTemplate;
