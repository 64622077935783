import { Card } from 'react-bootstrap';
import { useState } from "react";
import RoleList from '../components/RoleList';

const Users = () => {
    const [roles, setRoles] = useState([]);

    return (
        <>     
            <Card.Title>Roles </Card.Title> 
            <Card.Body>
                <RoleList roles={roles} setRoles={setRoles} />
            </Card.Body>   
        </>
    )
}

export default Users
