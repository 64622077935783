import { Card } from 'react-bootstrap';
import { useState, useEffect } from "react";
import MoldList from '../components/MoldList';
import useLocalStorage from "../hooks/useLocalStorage";
import {  useTranslation } from 'react-i18next';

const Molds = () => {
    const [molds, setMolds] = useState([]);
    const [moldlist, setMoldlist] = useState({});
    const [query, setQuery] = useLocalStorage('search_mold', '');

    const { t } = useTranslation();

    useEffect(() => {   
        function search(){
            const results = molds.filter(molds => {
                if (query === "") return molds
                return molds.code.toLowerCase().includes(query.toLowerCase()) || molds.product.toLowerCase().includes(query.toLowerCase())
            })

            setMoldlist(results);
        }

        search();       
    }, [molds, query]);

    return (
        <>     
            <Card.Title>
            {t('moldConnection.moldConnection_plural')}
            </Card.Title> 

            <Card.Body>
                <MoldList molds={molds} setMolds={setMolds} moldlist={moldlist} query={query} setQuery={setQuery} />
            </Card.Body>            
        </>
    )
}

export default Molds
