import React from "react";

import LabelValue from "../components/OverviewLabelValue";
import Status from "../components/OverviewStatus";
import Change from "../components/OverviewChange";
import StackedSeries from "../components/OverviewStackedSeries";

const Components = {
  status: Status,
  labelValue: LabelValue,
  change: Change,
  stackedSeries: StackedSeries,

};


export default (block, mold_data) => {
  // Component exists
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      block: block, 
      mold_data: mold_data
    });
  }
  // component doesn't exist (yet)
  return React.createElement(
    () => <div>The component {block.content} has not been created yet.</div>,
  );
};
