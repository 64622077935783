import { useTranslation } from 'react-i18next';

const LabelValue = ({block, mold_data}) => {   

    const { t } = useTranslation();

    return (
   <>
   <div className="p-3">
        <h3 className="mb-2">{(block.label ? t(block.label) : block.name)}</h3>
        {(mold_data[block.value] ? mold_data[block.value] : 'No value found for ' + block.value)}
    </div>
   
   </>
    
    );
}
export default LabelValue;

