import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from '../hooks/useAuth';
import { Card, Container, Col, Row, Alert } from 'react-bootstrap';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import RequestEmail from '../emails/Request';
import { render } from "@react-email/render";
import { useTranslation } from 'react-i18next';

export const SupportMail = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [email, setEmail] = useState(auth.userInfo.email);
    const [name, setName] = useState(auth.userInfo.name);
    const [request, setRequest] = useState(auth.userInfo.name);
    const [notice, setNotice] = useState('')
    const [noticeType, setNoticeType] = useState('');

    const { t } = useTranslation();


    const sendMail = async e => {
        try {
            const data = { 
                users_id: auth.userInfo.id,
                to: '', 
                subject: 'Support request - ' + name, 
                html: render(<RequestEmail type="Support request" name={name} contact={email} request={request}/>)
            };

            await axiosPrivate.post(`/email`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.log(error.response.data.message)  
                    throw t('messages.submitError');
                }
            }); 

            setNotice(t('messages.submitSucces'));
            setNoticeType('success'); 
        } catch (error) {
            setNotice(error);
            setNoticeType('danger'); 
        }
    }

return(
    <>
        <Row>     
            <Col xs={12}>
                <label htmlFor="name">{t('name')}</label>
                <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Col>
            <Col xs={12}></Col>
            <Col xs className="mb-4">
                <label htmlFor="email">{t('eMail')}</label>
                <input
                    type="text"
                    id="email"
                    autoComplete="off"
                    required
                    placeholder="user1@example.com, user2@example.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Col>  
            <Col xs={12}>
                <label htmlFor="email">{t('yourRequest')}</label>
                <textarea onChange={e => setRequest(e.target.value)}></textarea>
            </Col>

            <Col sm={12} className="my-3">     
                {(notice !== '' ? <Alert className="my-2" show={true} variant={ noticeType }>{ notice }</Alert> : '')}    
                <ButtonComponent className="primary float-end" onClick={e => sendMail(e)}>
                {t('buttons.submit')}
                </ButtonComponent>
            </Col> 
        </Row>
    </>
)
}

export default SupportMail ;