import React from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ColorPickerComponent, NumericTextBoxComponent   } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

// Definiere die Stateless Functional Component
const LimitStatic = ({ name, color, setColor, value, setValue,  placeholder, max, min, useActivate, status, setStatus, format, step }) => {
    
    const handleColorChange = (to) => {
        setColor(to.currentValue.hex);
    };

    const handleValueChange = (to) => {
        setValue(to.value);
    };

    const handleActiveChange = (to) => {
        setStatus(to.checked);
    };

    // useEffect(() => {
    //     if(min){
    //         if(min > value){
    //             setValue(min);
    //             console.log('updated')
    //         }
    //     }
    // }, []);
   


  return (
    <>
    <Col sm={12} className='mb-2'>
        <Row>
            {(color ? 
            <>
                <Col sm={3} className='clear'>
                    <ColorPickerComponent id={`color_${name}`} value={color} mode="Palette" change={(to) => handleColorChange(to)} name="cp2" cssClass='mt-4'/>
                </Col>
            </>
            : null)}
            <Col sm={ 6 }>
                <NumericTextBoxComponent
                id={`value_${name}`}
                format={format? format : 'n'}
                step={step ? step : 1} 
                value={value}  
                enabled={(useActivate ? status : true )} 
                onChange={e => handleValueChange(e)}
                placeholder={placeholder ? placeholder : null}
                min={min ? min : null}
                max={max ? max : null}
                floatLabelType='Always'
                /> 
            </Col>
            {(useActivate ? 
            <Col sm={3}>
                <CheckBoxComponent cssClass='mt-4' checked={status} change={(to) => handleActiveChange(to)} />
            </Col>
            : null )}
        </Row>
    </Col>
    </>
  );
};

export default LimitStatic;