import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { useState, useEffect, useRef } from "react";
import DashboardComponent from "../hooks/dashboardComponents";
import useAuth from "../hooks/useAuth";
import { Row, Col, Button, Card } from 'react-bootstrap';

const DashboardPage = ({ selectedTool, standorte, allowedWidgets }) => {

    let dashboardObj;
    let saveBtnobj;     

     // display panels in <dashboard>
    let panels = [];

    const axiosPrivate = useAxiosPrivate();   
    const { auth } = useAuth();
    const [dashboardSettings, setDashboardSettings] = useState([]); // to fill with active Widgets from Usersettings

    
    const [templateId, setTemplateId] = useState(0);
    const [templates, setTemplates] = useState([]);
    const templateNameRef = useRef(''); // input für Template name

    const renderWidget = (elem) => {
        return(DashboardComponent(elem, selectedTool ));

    }


    const fallback = { 
         "id" : "9999", 
         "sizeX": 4, 
         "sizeY":1, 
         "col" : 1,
         "minSizeY": 1, 
         "minSizeX": 4, 
         "description": "Fallback Component", 
         "content": () => renderWidget('fallbackPanel')
        };
    
    // load Dashboardtemplates
    useEffect(() => {
        const getTemplates = async () => {
            const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });

            setTemplates(response.data.dashboard_templates);
        }

        getTemplates();

        return () => {
        }
    }, [templateId]) // muss


    // get active panels/settings from userInfo
    // listens to standorte change / Aber warum? Braucht es, damit es bei neuaufruf die Widgets rendert
    useEffect(() => {
        const getDashboardSettings = async () => {
            const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {
            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });   
              
            let settings = response.data.dashboard_settings;
            setDashboardSettings([...settings])     
      
        }
      
        getDashboardSettings();
        
        return () => {
        }
    }, [standorte])

    
    // compare dashboardSettings[] with allowedWidgets[] (from Home.js) and fill data for panels[]
    useEffect(() => {
        panels = [];
       //Runs on first render + [dashboardSettings] change      
        for(let i = 0; i < allowedWidgets.length; i++){
            let res = dashboardSettings.find(e => e.id == 'panel_' + allowedWidgets[i].id);
            
            if(res !== undefined){
             
                let  widget_to_add = {  
                id: res.id, 
                sizeX: res.sizeX, 
                sizeY: res.sizeY, 
                row: res.row, 
                col: res.col,
                description: allowedWidgets[i].description,
                content: () => renderWidget(allowedWidgets[i].content)
                }

                panels.push(widget_to_add);

            }      
           
        }

        fillDashboard();

    }, [dashboardSettings]);

    useEffect(() => {
        fillDashboard();
      }, []);


    // Fill <dashboard> with panels[]
    const fillDashboard = () => {
        
        if(panels.length == 0){
            panels.push(fallback); 
        }
        if(panels.length > 1){
            if(panels.some(obj => obj.id === "9999")){
                let remove = panels.indexOf(panels.find(e => e.id === "9999"));

                panels.splice(remove, 1);
            }

    
        }
        dashboardObj.panels = panels; 
        // document.getElementById('defaultLayout').ej2_instances[0].panels = panels; 
    }
    
    // check dashboardSettings if widget is active
    function isWidget(widget_id){
        let objWithIdIndex = dashboardSettings.findIndex((obj) => obj.id === 'panel_' + widget_id);

        if (objWithIdIndex > -1) {
            return true;
        }   

        return false;
    }

    function addWidget(widget_id, widgetObj){
        let widget_to_add = '';
        if(widgetObj !== undefined){
            let res = allowedWidgets.filter((item) => { return item.id == widgetObj.id.substring(6); });
            widget_to_add = {
                id: widgetObj.id, 
                sizeX: widgetObj.sizeX, 
                sizeY: widgetObj.sizeY, 
                minSizeY: widgetObj.minSizeY, 
                minSizeX: widgetObj.minSizeX, 
                row: widgetObj.row, 
                col: widgetObj.col,
                description: res[0].description,
                content: () => renderWidget(res[0].content)
            }
        }
        else{
     
            let res = allowedWidgets.filter((item) => { return item.id == widget_id; });
            widget_to_add = { 
                id: `panel_${widget_id}`, 
                sizeX: res[0].sizeX, 
                sizeY: res[0].sizeY, 
                minSizeY: res[0].minSizeY, 
                minSizeX: res[0].minSizeX, 
                // row: res[0].row, 
                // col: res[0].col,
                description: res[0].description,
                content: () => renderWidget(res[0].content)
            }
        }
        panels.push(widget_to_add);
        fillDashboard();
    }

    function removeWidget(widget_id){

            let widgettodelete = 'panel_' + widget_id;
            // panels.filter(e => e.id !== widgettodelete); // hat irgendwie nicht funktioniert?!

            let remove = panels.indexOf(panels.find(e => e.id === widgettodelete));

            panels.splice(remove, 1);
            fillDashboard();
    }

    // add / remove widget 
    // anpassen an Templates
    const changeWidget = (widget_id, widget) =>{
        // let panel = document.getElementById('panel_' + widget_id);
        // let panelcontent = document.getElementById('panel_' + widget_id + '_content');
        // let panelbody = document.getElementById('panel_' + widget_id + '_body');
        let thumbid = (widget == undefined ? widget_id : widget_id.substring(6));
        let thumb = document.getElementById( 'panel_img_' + thumbid);
        if(widget !== undefined){ 
            // addwidget from Object instead of ID
           

            addWidget(widget_id, widget);
            thumb.classList.add("activeWidget"); 
      

            // document.getElementById(addedWidget.id).classList.remove("add");
            // document.getElementById(addedWidget.id).classList.add("remove"); 
        }
        else{
             // removeWidget
        if(thumb.classList.contains('activeWidget')){
            thumb.classList.remove("activeWidget");
            removeWidget(widget_id);
        }
        // addWidget
        else{
            thumb.classList.add("activeWidget"); 
            addWidget(widget_id);
        }  
    

        }
        
    }

     // sollte man schöner gestalten
    const btnClick = () => {

        // isToggle = sets state from normal to active -> classList + e-active.
        if (saveBtnobj.element.classList.contains('e-active')) {
            dashboardObj.allowDragging = true;
            // dashboardObj.showGridLines = true;
            document.getElementById('mainSidebar').classList.add("visible");
            saveBtnobj.element.classList.add("mainSidebarVisible");
        
        }
        else {
            dashboardObj.allowDragging = false;
            // dashboardObj.showGridLines = false;
            document.getElementById('mainSidebar').classList.remove("visible"); 
            saveBtnobj.element.classList.remove("mainSidebarVisible");

             //setTemplateId(0); // muss ggf. wieder rein wegen Templates -> Nice to have um änderung eines Templates zu triggern
            // Serialize Funktionen entfernt 
             saveDashboardsettings();
           
        }
    }
    const saveDashboardsettings = async () => {
        let serialized = dashboardObj.serialize();

        try {
            const data = { id: auth.userInfo.id, dashboard_settings: serialized };
         
            await axiosPrivate.patch(`/users`, {
                data
            })  
            .catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
        } catch (error) {
            console.log(error)    
         
        }
       
    }

    const selectTemplate = async (templateId) => { 
        const response = await axiosPrivate.get('/templates/' + templateId, {
        }).catch(function(error){
            if (error.response) {
                console.error(error.response.data.message);
            }
        });
  
        templateNameRef.current.value = response.data.name;
        setTemplateId(templateId);

        document.getElementById('defaultLayout').ej2_instances[0].removeAll();
        // dashboardObj.removeAll();

        //console.log('Select template');
        response.data.dashboard_settings.map((widget) => changeWidget(widget.id, widget));
        //console.log(response.data.dashboard_settings);
        
    }
    
    const removeTemplate = async () => { 
        const data = { id: templateId };

        await axiosPrivate.delete(`/templates`, {
            data
        }).catch(function(error){
            if (error.response) {
                console.error(error.response.data.message);
            }
        });
       
        setTemplateId(0);
        templateNameRef.current.value = '';
    }

    const saveAsTemplate = async () => { 
        if(templateId === 0){
            const data = { users_id: auth.userInfo.id, name: templateNameRef.current.value, dashboard_settings: dashboardObj.serialize() };
            
            const response0 = await axiosPrivate.post(`/templates`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });

            const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
         
            setTemplateId(response0.data.id);
            setTemplates(response.data.dashboard_templates);
        }
        else{
            const data = { id: templateId, name: templateNameRef.current.value, dashboard_settings: dashboardObj.serialize() };

            await axiosPrivate.put(`/templates`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
        }

        // setTemplateId(58);
        templateNameRef.current.value = '';
    }

    const cellSpacing = [12, 12];

    
    return (
    <div>
        <div id='edit_target' className="control-section">
            <div id="mainSidebar" className="">
                <ButtonComponent 
                    cssClass='sidebarToggler' 
                    ref={(scope) => { saveBtnobj = scope; }} 
                    isToggle={true} 
                    onClick={() => btnClick()} 
                    title="Edit Screen"
                >     
                </ButtonComponent>

                
               
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button 
                            className="nav-link active font-small" 
                            id="widget-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#widgetSelector" 
                            type="button" 
                            role="tab" 
                            aria-controls="widgetSelector" 
                            aria-selected="true"
                        >
                            Widgets
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button 
                            className="nav-link font-small" 
                            id="template-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#templateSelecgtor" 
                            type="button" 
                            role="tab"
                            aria-controls="profile" 
                            aria-selected="false"
                        >
                            Templates
                        </button>
                    </li>
                </ul>
              
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="widgetSelector" role="tabpanel" aria-labelledby="widget-tab">
                            <div id="dropdownbox">
                                <ul className="widgetbox">

                                {
                                allowedWidgets.map((widget) => (   
                                    <li key={widget.id} >
                                        <Card 
                                            id={'panel_img_' + widget.id}  
                                            onClick={() => changeWidget(widget.id)}
                                            className={isWidget(widget.id) ? 'activeWidget' : ''} 
                                        >
                                            <Card.Header>
                                                {widget.description}
                                            </Card.Header>
                                            <Card.Body className="thumbPreview" style={{backgroundImage : "url(" + widget.thumbnail + ")"}} />
                                        </Card>
                                    </li>
                                ))
                                }
                                </ul>
                            </div>
                        </div>
                        
                        <div className="tab-pane fade" id="templateSelecgtor" role="tabpanel" aria-labelledby="template-tab">

                      

                            <input
                                type="text"
                                ref={templateNameRef}
                            />

                            <Button
                                onClick={() => saveAsTemplate()}
                            >   
                                Save {templateId === 0 ? 'as new' : 'changes'}  
                            </Button>

                            { 
                            templateId !== 0 ? 
                                <Button 
                                    variant="danger"
                                    onClick={() => removeTemplate()}
                                >   
                                    Remove template  
                                </Button> : 
                                ''
                            }

                            <ul className="widgetbox">

                            {
                            templates.map((template) => (
                                <li 
                                    key={template.id+template.name}
                                >
                                    <Card 
                                        id={'template_img_' + template.id}  
                                        onClick={() => selectTemplate(template.id)} 
                                        className={template.id == templateId ? 'activeWidget' : ''} 
                                    >
                                        <Card.Header 
                                          
                                        >
                                            {template.name}
                                        </Card.Header>
                                    </Card>
                                </li>
                            ))
                            }

                            </ul>
                        </div>  
                    </div>    
              
            </div>
      
            <Row>
                <Col s={{span: 12, offset: 0}} lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }} className="py-5">
                    <DashboardLayoutComponent 
                        id="defaultLayout"
                        columns={6} 
                        // panels = { (showFallback ? fallback : panels) }
                        panels = { panels }
                        cellSpacing={cellSpacing} 
                        ref={s => (dashboardObj = s)}
                        allowResizing={false} 
                        allowDragging={false}    
                        draggableHandle='.widgetheader'
                        // panels = {allowedWidgets}
                        // enablePersistence={true}
             
      
        
                    >     
                    {/* {panels == '' ? 'leer' : 'voll'} */}
                    </DashboardLayoutComponent>

                 

                </Col>
            </Row>   
    
        </div>    
    </div>);
  
}

  

export default DashboardPage;