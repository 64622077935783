import { Html } from '@react-email/html';
import { Head } from '@react-email/head';
import { Body } from '@react-email/body';
import { Container } from '@react-email/container';

const AlertEmail = (props) => {
    const STYLES = `
    body {
        background-color: #E9E9E9;
    `;

    return (
      <Html lang="en">
        <Head>
            <title>HACK digital solutions</title>
            <style>
                {STYLES}
            </style>
        </Head>
        <Body>
            <Container>
                <h1>Alert</h1>
                {props.alert} in Mold {props.mold}
            </Container>
        </Body>
      </Html> 
    )
}

export default AlertEmail