export const criticals = {
    'production halted': 'Production was halted', 
    'part stuck': 'Plastic part stuck in mold', 
    'parallelism critical': 'Plate parallelism in critical state', 
    'temperature critical': 'Plate temperature in critical state'
};

export const errors = {
    'defect sensor': 'Defect Sensor - Triggered when a Sensor is not emitting data', 
    'database error': 'Database not accessible', 
    'network error': 'Network not accessible'
};

export const warnings = {
    'new user login': 'New user login', 
    'new mold online': 'New mold online', 
    'next predicted maintenance': 'Next predicted maintenance', 
    'download active': 'Download active',
    'gps not active': 'GPS of machines not activated'
};