import axios from '../api/axios';
import useAuth from './useAuth';
import jwt_decode from "jwt-decode";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });

        
            const userInfo = jwt_decode(response.data.accessToken).userInfo;
     
            setAuth({ accessToken: response.data.accessToken, userInfo });

/*
        setAuth(prev => {
            //console.log('pre ' + JSON.stringify(prev));
            //console.log('act ' + response.data.accessToken);
            return {
                ...prev,
                accessToken: response.data.accessToken
            }
        });*/
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
