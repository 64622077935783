import React from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const ToggleDark = ({ dark, toggle, label}) => {
  
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const data = { id: auth.userInfo.id, darkmode: dark };
         
       axiosPrivate.patch(`/users`, {
          data
      })

  return (
    <div>
      {/* <label for="switch">Darkmode
      <SwitchComponent 
        onClick={() => {
          toggle();
        }}
        id="switch"
        checked={dark}
      />
      </label> */}
      <label className="cbox">
      {label}
            <input 
                id="switch"
                onChange={() =>  toggle()}
                // checked={isAlert(`${alert_key}`)}
                checked={dark}
                type="checkbox"
                />
            <span className="checkmark"></span>
        </label>

     

    </div>
  );
}

export default ToggleDark;