import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, ScatterSeries, LineSeries, Zoom  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';


// Datepicker fomrat:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

   
const Centering = ({ selectedTool }) => {

    let chart = useRef(null); 
    const axiosPrivate = useAxiosPrivate(); 

    createSpinner({
        target: document.getElementById('panel_8_content'),
    });
    const [message, setMessage] = useState('Select mold from map to continue');

    let optimal =[{"x": 0, "y": 0 }]

    const [offsetData, setOffsetData] = useState([]);

    const date = new Date();
    
    const [dateRange, setDaterange] = useState(7);
  


   
    // const today = new Date(date);
    // const tomorrow = new Date(date);
    // tomorrow.setDate(date.getDate() + 1);

    // let LSStart = localStorage.getItem('session_centering_start');
    // let LSEnd = localStorage.getItem('session_centering_ende');

    // var diffDays = tomorrow.getTime() - today.getTime(); 
    // diffDays = diffDays / (1000 * 3600 * 24);

    // const [dateRange, setDaterange] = useState(diffDays);


    // const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    // const [scatterDaterange, setScatterDaterange] = useState([ new Date(LSStart ? LSStart :  today), new Date(LSEnd ? LSEnd :  tomorrow) ]);// first and last day of current week

    
    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    let LSStart = localStorage.getItem('session_offset_start');
    let LSEnd = localStorage.getItem('session_offset_end');


    const [offsetDateRange, setOffsetDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(offsetDateRange[0]);
    let end = new Date(offsetDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
    let helpDialogInstance;




    // let gc1 = '#00ff4f';
    // let gc2 = '#00ffa9';
    // let gc3 = '#00ffe2';
    // let gc4 = '#00eaff';
    // let gc5 = '#007eff';
    
    // let gc1 = '#6acab2';
    // let gc2 = '#52c1a5';
    // let gc3 = '#39b898';
    // let gc4 = '#20af8b';
    // let gc5 = '#08a77f';

    let gc1 = 'rgba(8,167,127, 1)';
    let gc2= 'rgba(8,167,127, .8)';
    let gc3 = 'rgba(8,167,127, .6)';
    let gc4 = 'rgba(8,167,127, .4)';
    let gc5 = 'rgba(8,167,127, .2)';


    

    let gcTest = '#08a77f';


    useEffect(() => {

        showSpinner(document.getElementById('panel_8_content'));
        let unMounted = false;
        const getCenterData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

                let start = new Date (offsetDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (offsetDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                
                const response = await axiosPrivate.get('/molddata/offset/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
            !unMounted &&
            setOffsetData([...response.data])
            if(response.data.length === 0){
                setMessage('Change daterange to view Noise IDN');
            }

            }
            else{
                setMessage('Select mold from map and/or change daterange to view Noise IDN');   
            }
            hideSpinner(document.getElementById('panel_8_content'))   
           
        }

        getCenterData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, offsetDateRange]);  



    let gesamt = offsetData.length;

    let g1 = [];
    let g2 = [];
    let g3 = [];
    let g4 = [];
    let g5 = [];


    Object.keys(offsetData).forEach(function(key) {

       
        let obj = {  
            offset_h: offsetData[key].offset_h, 
            offset_v: offsetData[key].offset_v, 
        }
    
        if(key/gesamt < 0.2){
            g1.push(obj);  
        }   
        else if(key/gesamt < 0.4){
            g2.push(obj);  
        }
        else if(key/gesamt < 0.6){
            g3.push(obj);  
        }
        else if(key/gesamt < 0.8){
            g4.push(obj);  
        }
        else{
            g5.push(obj);  
        }
      
      });

    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'save',
                isPrimary: true,
            }
        }
    ];



    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
    //    let text = e.text; dd/mm/yyyy - dd/mm/yyyy
        setDaterange(e.daySpan);
        handleOffsetDateChange(start, end);
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end);


       
        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleOffsetDateChange(newstart, newend);
    };
    const handleOffsetDateChange = (start, ende) => { 
        let temparray = [start, ende] 
        setOffsetDateRange(temparray);
        localStorage.setItem('session_centering_start', start);
        localStorage.setItem('session_centering_end', ende);
     };
    let rgba = ' rgba(256,256,256,.3) ';

    const marker =  {
        visible: true, 
        // width: 10, 
        // height: 10, 
        // fill: PrimaryColor, 
        // border: { width: 2, color: PrimaryColor },
        // dataLabel: { visible: false, position: 'Top',   font: { fontWeight: '600', color: TextColor }  },
        // width: 4, height: 4, shape: 'Circle'
        visible: false, width: 2, height: 2, shape: 'Circle'
    };
    const centermarker =  {
        visible: true, 
        isFilled : true,
        shape: 'Circle',
        width: 240, 
        height: 240, 
        fill: rgba, 

        border: { width: 2, color: PrimaryColor },

    };
    const centercentermarker =  {
        visible: true, 
        isFilled : true,
        shape: 'Circle',
        width: 8, 
        height: 8, 
         fill: PrimaryColor, 
        // fill: PrimaryColor,
       
        // border: { width: 0, color: PrimaryColor },
        // dataLabel: { visible: true, position: 'Top',   font: { fontWeight: '600', color: TextColor }  },
        // width: 4, height: 4, 
    };

    const primaryxAxis = { 
        // valueType: 'DateTime',  
        title: 'Time', 
        // labelFormat: 'dd.MM - h:mm',
        //  interval: 1, 
        labelRotation:  -45 , 
       
        labelStyle: {color: TextColor}, 
        majorGridLines: { width: 0, },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        enableAutoIntervalOnZooming: true,
        minimum: -200, maximum: 200
    };
    const primaryyAxis = {
        // title: 'Value',
         majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: {width: 0  },
        labelStyle: {color: TextColor}, 
        minimum: -200, maximum: 200
    };
    const refreshChart = () => { 
        chart.current.refresh();
  
    }
const margin = {  left: 100, right: 100, top: 40, bottom: 40};
const helpButtonClick = () => {
    helpDialogInstance.show();
}
const loaded = () => {
    hideSpinner(document.getElementById('panel_8_content'));
}

return (
<>   
    <div className="widgetheader">
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Centering' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_8_content'>  Explanation for Centering Widget  </DialogComponent>

        <DialogComponent 
        id="defaultdialogScatter" 
        showCloseIcon={true} 
        zIndex = {9999}
        width="500px" 
        header="Settings" 
        visible={status}
        target="#panel_8_content"
        buttons={buttons}
        open={dialogOpen}
        close={dialogClose}
        >
          <Row>                
           <Col>
          No settings available
        
            </Col>
        </Row>
        </DialogComponent>
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={start} 
            endDate={end} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"}  /> </div> 


{(offsetData.length == 0 ? <h2 className='text-center pt-5'>{ message }</h2> : 
    <div className='control-pane'>
    <ChartComponent 
        style={{ backgroundColor : BackgroundColor, color: TextColor}}
        background={BackgroundColor}
        primaryXAxis={primaryxAxis} 
        primaryYAxis={primaryyAxis} 
        margin={margin}         
        enableCanvas={true}
        enablePersistence={true}
        loaded = {loaded}
        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            toolbarItems: ['Reset'],
            mode: "X,Y",
        }}
        title='Centering'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
        chartArea={{ border: { width: 0 } }} 

        >
        <Inject services={[ScatterSeries,LineSeries, Legend, DataLabel, Category, Zoom, DateTime]}/>
        <SeriesCollectionDirective>
        {/* <SeriesDirective dataSource={data} xName='x' yName='y' name="Scatter2" type='Scatter' fill={Red} opacity='0.7' marker={marker}></SeriesDirective>  */}
        {/* <SeriesDirective dataSource={offsetData} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={Red} opacity='0.7' marker={marker}></SeriesDirective>  */}
        {/* <SeriesDirective dataSource={g1} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={gc1} opacity='0.5' marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g2} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={gc2} opacity='0.5' marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g3} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={gc3} opacity='0.5' marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g4} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={gc4} opacity='0.5' marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g5} xName='offset_h' yName='offset_v' name="Offsetdata" type='Scatter' fill={gc5} opacity='0.5' marker={marker}></SeriesDirective>  */}

        <SeriesDirective dataSource={g1} xName='offset_h' yName='offset_v' name="Offsetdata 1" type='Scatter' fill={gc1}  marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g2} xName='offset_h' yName='offset_v' name="Offsetdata 2" type='Scatter' fill={gc2} marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g3} xName='offset_h' yName='offset_v' name="Offsetdata 3" type='Scatter' fill={gc3}  marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g4} xName='offset_h' yName='offset_v' name="Offsetdata 4" type='Scatter' fill={gc4}  marker={marker}></SeriesDirective> 
        <SeriesDirective dataSource={g5} xName='offset_h' yName='offset_v' name="Offsetdata 5" type='Scatter' fill={gc5} marker={marker}></SeriesDirective> 
        
        
        <SeriesDirective dataSource={optimal} xName='x' yName='y'
         name="Radius" 
         type='Line' 
         opacity='0.5' 
         marker={centermarker} 
        //  fill={Red}
          ></SeriesDirective>
         <SeriesDirective dataSource={optimal} xName='x' yName='y'
         name="Optimal center" 
         type='Line' 
         opacity='0.5' 
         marker={centercentermarker}
        //   fill={Red}
           ></SeriesDirective>
            {/* <SeriesDirective dataSource={data} xName='x' yName='y' name="Scatter1" type='Scatter' fill={PrimaryColor} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data2} xName='x' yName='y' name="Scatter2" type='Scatter' fill={Red} opacity='0.7' marker={marker}></SeriesDirective> */}
     
        </SeriesCollectionDirective>

    </ChartComponent>
    </div> 
)}
</>
)

}
;
export default Centering;
