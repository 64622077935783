import { useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import EditUser from './EditUser';
import { Table, Form, Col, Row } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {  useTranslation } from 'react-i18next';

const UserList = ({ users, setUsers, userlist, query, setQuery }) => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const { t } = useTranslation();

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
              
                setUsers(response.data.users);
            } catch (error) {
                console.log(error);
            }
        }

        getUsers();

        return () => {
        }
    }, [])
   
    if(!auth.userInfo.modes.includes(21)){ return false; }

    return (
        <>
            <Row className="mb-3"> 
                <Col sm={8}>
                    <Form.Control 
                        type="text" 
                        placeholder="Search..." 
                        className="mb-2" 
                        value={query}
                        onChange={(e) => setQuery(e.target.value)} 
                    />
                </Col>
                <Col sm={4} className="float-end">
                    <EditUser user={{id: 0}} users={users} setUsers={setUsers} />                 
                </Col>
            </Row>
            

            <Table>
                <thead>
                    <tr>
                        <th>{t('name')}</th>
                        <th>{t('eMail')}</th>
                        <th>{t('role.role')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
            {userlist?.length
                ? (userlist.map((user, i) => 
                    <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role_name}</td>
                        <td>{user.key_user !== true ? <EditUser user={user} users={users} setUsers={setUsers} /> : <ButtonComponent className="primary float-end">{t('keyUser')}</ButtonComponent>}</td>
                    </tr>)) 
                : ''
            }
               
                </tbody>
            </Table>
        </>
    );
};

export default UserList;
