import { useState, useEffect } from "react";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import FormatDate from './FormatDate';
import HourlyDivs from './HourlyDivs';
import { useTranslation } from 'react-i18next';

const LabelValue = ({block, mold_data}) => {   

    const axiosPrivate = useAxiosPrivate();   
    const { t } = useTranslation();

    var nowDate = new Date(); 
    var date = nowDate.getFullYear() + '-' + (nowDate.getMonth()+1) + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':00:00'; 
    var yesterday = new Date(nowDate.setDate(nowDate.getDate() - 1));
    var yesterdaySameTime =  yesterday.getFullYear() + '-' + (yesterday.getMonth()+1) + '-' + yesterday.getDate() + ' ' + yesterday.getHours() + ':00:00'; 


    // yesterdaySameTime ='2024-07-16 12:00:00';
    // date = '2024-07-17 12:00:00';

    const [data, setData] = useState(); 
 
    const [timeblocks, setTimeblocks] = useState([]);

    const format = 'dd.mm.YYYY hh:ii';
    const renderDate = (elem, elem2, status ) => {
        return(
            <>
               {status.charAt(0).toUpperCase() + status.slice(1)}: <FormatDate date={elem} format={format} /> - <FormatDate date={elem2} format={format} />
            </>
            );

    }

    useEffect(() => {    
        let unMounted = false;
    const updateData = () => {
        if(data){
            setTimeblocks(
                data.map((val) =>{
                    const date1 = new Date((val.start.replace('T', ' ').replace('Z', ''))); 
                    const date2 = new Date((val.end.replace('T', ' ').replace('Z', ''))); 
                    // const date2 = new Date(val.end);                    
                    const diffTime = Math.abs(date2 - date1);

    
                    const diff = ((diffTime / (1000*60*60*24))*100);
                    const size = Math.floor(diff * 100) / 100;
               
                    return(
                        {...val, size: size}
                    )
                 
                    }
                )    
                
            )
          
        }       
    }
    updateData();   
    return () => {
        unMounted = true;

    }
    }, [data])
    
    useEffect(() => {   
        const get24HData = async () => {
            if(!mold_data.id == 0 ){
                const response = await axiosPrivate.get('/molddata/insections/1/mold_id/'+ mold_data.id +'/start/' + yesterdaySameTime + '/end/' + date , {
                }).catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
            response &&
            setData([...response.data]);    
            }     
        }
        get24HData();
    }, [])


    let test = 0;
   
    return (
   <>

    <h3 className="mb-2 mt-3">{(block.label ? t(block.label) : block.name)}</h3>
        <div className="stackedseries">
        
            {data ? (timeblocks.map((d, index) => (
                <TooltipComponent 
                    content={() => renderDate(d.start, d.end, d.status)}
                    opensOn='Hover' 
                    target={`#block${index}`} 
                    key={index}
                >
                    <div className={`timeblock ${d.status}`} id={`block${index}`} style={{ width : `${d.size}%`}}></div>
                 
                </TooltipComponent>
                )

                
               
            )) : <div className="timeblock stop" style={{ "width" : "100%", "color" : "#000"}}>no data</div> } 


        
        </div>
    <div className="legend">
        <HourlyDivs startTime={date } />
    </div>
   
   </>
    
    );
}
export default LabelValue;

